import { Component, Input, OnInit } from '@angular/core';
import { Language } from '../../../models/language';
import { I18nService } from '../../../core/services/i18n.service';
import { Router } from '@angular/router';
import { SecurityTokenStorage } from 'src/app/services/security/security-token-storage';
import { UserRemoteService } from 'src/app/core/services/remote/user/user.remote.service';
import { UserToken } from '../../../models/security/user-token';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { AppleAuthenticationService } from 'src/app/auth/services/apple-authentication.service';
import { EventService } from 'src/app/core/services/event.service';
import { CommsService } from 'src/app/core/services/comms.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  currentLanguage: Language;
  @Input() footer: boolean;
  private userLogged: any;
  languageId: number;
  mireia = false;
  footerClass = 'lang-panel';

  constructor(
    private i18nService: I18nService,
    private router: Router,
    private userService: UserRemoteService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private appleAuthenticationService: AppleAuthenticationService,
    private authenticationService: AuthenticationService,
    private commsService: CommsService
  ) {
    // this.authenticationService.get().subscribe((userLogged) => {
    //   this.userLogged = userLogged;
    // });
  }

  ngOnInit() {
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    this.commsService.getIsMireia().subscribe((value) => {
      this.mireia = value;
    });

    this.footerClass = this.footer ? 'lang-panel-footer' : 'lang-panel';
  }

  public getOthersThanCurrentLanguage(): Language[] {
    return I18nService.getSupportedLanguages().filter(
      (x) => x.code !== this.i18nService.getCurrentLanguage().code
    );
  }

  setCurrentLanguage(code: string) {
    this.i18nService.setCurrentLanguage(code);
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    this.userService.changeLanguage(this.currentLanguage.code).subscribe();
    if (this.userLogged != null) {
      if (this.currentLanguage.code !== null) {
        if (this.currentLanguage.code === 'es') {
          this.languageId = 1;
        } else if (this.currentLanguage.code === 'pt') {
          this.languageId = 3;
        } else if (this.currentLanguage.code === 'fr') {
          this.languageId = 4;
        } else if (this.currentLanguage.code === 'eus') {
          this.languageId = 5;
        } else if (this.currentLanguage.code === 'cat') {
          this.languageId = 6;
        } else {
          this.languageId = 2;
        }
        this.appleAuthenticationService.loadScript();
        this.userService
          .editLanguage(this.userLogged.id, { language_id: this.languageId })
          .subscribe(
            (response) => {
              // this.currentLanguageCode = languageCode;
            },
            (error) => {
              // console.log(error);
              // console.log('Error en servicio de actualizar idioma del usuario.');
            }
          );
      }
    }
    //this.reloadCurrentRoute();
  }

  private reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url;

    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url]);
    });
  }
}
