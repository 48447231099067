import { Component, HostListener, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Configuration } from 'src/app/models/common/configuration';
import { ConfigurationRemoteService } from 'src/app/services/common/configuration-remote.service';
import { Model } from '../../../models/model';
import { ModelRemoteService } from '../../../core/services/remote/model/model-remote.service';
import { EventService } from 'src/app/core/services/event.service';
import { EventData } from 'src/app/core/models/event-data';
import { environment } from 'src/environments/environment';
import {
  ImageVideosConfig,
  NrInfoRemoteService,
} from 'src/app/core/services/remote/nr-info-remote.service';

@Component({
  selector: 'app-add-hyperspectral-dialog',
  templateUrl: './add-hyperspectral-dialog.component.html',
  styleUrls: ['./add-hyperspectral-dialog.component.scss'],
})
export class AddHyperspectralDialogComponent {
  model: Model;
  url: string;
  minDropzoneImages: number;
  maxDropzoneImages: number;
  acceptedFilesDropzoneImages: string;

  constructor(
    public dialogRef: MatDialogRef<AddHyperspectralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Model,
    private router: Router,
    private configurationRemoteService: ConfigurationRemoteService,
    private modelRemoteService: ModelRemoteService,
    private eventService: EventService,
    private nrInfoRemoteService: NrInfoRemoteService
  ) {
    this.model = data;
    if (this.model) {
      forkJoin([
        this.getImagesConfig(),
        this.getPhotogrammetryAcceptedFiles(),
      ]).subscribe((response) => {
        this.url = `${environment.apiUrl
          }/files/upload/${this.model.id.toString()}`;
        this.minDropzoneImages = (response[0] as ImageVideosConfig).min;
        this.maxDropzoneImages = (response[0] as ImageVideosConfig).max;
        this.acceptedFilesDropzoneImages = (response[1] as Configuration).value;
      });
    }
    this.url = `${environment.apiUrl}/files/upload/${this.model.id.toString()}`;
  }

  closeDialog(): void {
    this.eventService.emit(new EventData('closeDialogHyperspectral', null));
    this.dialogRef.close();
  }

  processModel() {
    this.modelRemoteService
      .sendToGenerateHyperespectral(this.model)
      .subscribe((model) => {
        // this.router.navigateByUrl('/models-processing').then();
        this.dialogRef.close();
      });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  private getImagesConfig() {
    return this.nrInfoRemoteService.getImagesInfo();
  }

  private getPhotogrammetryAcceptedFiles() {
    // Obtenemos los ficheros soportados para la fotogrametria
    return this.configurationRemoteService.get('ACCEPTED_FILES_PHOTOGRAMMETRY');
  }
}
