import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-control-panel-save-csv-no-more-users',
  templateUrl: './company-control-panel-save-csv-no-more-users.component.html',
  styleUrls: ['./company-control-panel-save-csv-no-more-users.component.scss'],
})
export class CompanyControlPanelSaveCsvNoMoreUsersComponent {
  constructor(
    private dialogRef: MatDialogRef<CompanyControlPanelSaveCsvNoMoreUsersComponent>,
    private router: Router
  ) { }

  public closeDialog() {
    this.dialogRef.close();
  }

  public goToPersonalArea(): void {
    this.router.navigateByUrl(
      'profile/user-account-layout/personal-area/active-subscriptions'
    );
    this.closeDialog();
  }
}
