export const IOTA_DIALOG_I18N_ENTRIES = {
  iota_Dialog_Data1: {
    es: '<p>NUEVO PROTOCOLO DE SEGURIDAD</p>',
    en: '<p>NEW SECURITY PROTOCOL</p>',
    pt: '<p>NOVO PROTOCOLO DE SEGURANÇA</p>',
    fr: '<p>NOUVEAU PROTOCOLE DE SÉCURITÉ</p>',
    eus: '<p>SEGURTASUN-PROTOKOLO BERRIA</p>',
    cat: '<p>NOU PROTOCOL DE SEGURETAT</p>',
  },

  iota_Dialog_Data2: {
    es: '<p>Seguridad por</p><p>IOTA Tangle</p>',
    en: '<p>IOTA Tangle</p><p>Security</p>',
    pt: '<p>Segurança por</p><p>IOTA Tangle</p>',
    fr: '<p>Sécurité par</p><p>IOTA Tangle</p>',
    eus: '<p>IOTA Tangle</p><p>bidezko segurtasuna</p>',
    cat: '<p>Seguretat per</p><p>IOTA Tangle</p>',
  },

  iota_Dialog_Data3: {
    es: '<p>eyesCloud3D utiliza el protocolo IOTA Tangle para</p><p>obtener los mayores estándares de seguridad de</p><p>los datos de nuestros usuarios, así como la</p><p>confiabilidad, integridad y trazabilidad de los</p><p>recursos empleados, las operaciones realizadas y</p><p>de los resultados obtenidos durante la utilización</p><p>de la plataforma.</p>',
    en: '<p>eyesCloud3D uses the IOTA Tangle protocol to</p><p>ensure the highest standards of security</p><p>for our users’ data, along with the</p><p>reliability, integrity, and traceability of the</p><p>resources used, operations performed</p><p>and results obtained while using</p><p>the platform.</p>',
    pt: '<p>eyesCloud3D utiliza o protocolo IOTA Tangle para</p><p>obter os mais elevados padrões de segurança dos dados dos</p><p>nossos utilizadores, bem como a</p><p>fiabilidade, integridade e rastreabilidade dos</p><p>recursos utilizados, das operações realizadas e</p><p>dos resultados obtidos durante a utilização</p><p>da plataforma.</p>',
    fr: '<p>eyesCloud3D utilise le protocole IOTA Tangle pour</p><p>garantir les meilleurs niveaux de sécurité des</p><p>données de nos utilisateurs, tout comme la</p><p>fiabilité, l’intégrité et la traçabilité des</p><p>ressources employées, des opérations réalisées et</p><p>des résultats obtenus pendant l’utilisation</p><p>de la plateforme.</p>',
    eus: '<p>eyesCloud3D-k IOTA Tangle protokoloa erabiltzen du</p><p>gure erabiltzaileen datuen segurtasun- estandar</p><p>handienak bermatzeko, bai eta erabilitako baliabideen,</p><p>  gauzatutako ekintzen eta plataforma erabiltzean</p><p>lortutako emaitzen fidagarritasuna, osotasuna</p><p>eta trazabilitatea bermatzeko ere.</p>',
    cat: '<p>eyesCloud3D utilitza el protocol IOTA Tangle per</p><p>obtenir els millors estàndards de seguretat de</p><p>les dades dels nostres usuaris, així com</p><p>la fiabilitat, integritat i traçabilitat dels</p><p>recursos emprats, les operacions efectuades</p><p>i els resultats obtinguts durant l’ús</p><p>de la plataforma.</p>',
  },

  iota_Dialog_Data4: {
    es: '<p>eyesCloud3D utiliza el protocolo IOTA Tangle para</p><p>obtener los mayores estándares de seguridad de los</p><p>datos de nuestros usuarios, así como la confiabilidad,</p><p>integridad y trazabilidad de los recursos empleados,</p><p>las operaciones realizadas y de los resultados obtenidos</p><p>durante la utilización de la plataforma.</p>',
    en: '<p>eyesCloud3D uses the IOTA Tangle protocol to</p><p>ensure the highest standards of security</p><p>for our users’ data, along with the reliability,</p><p>integrity, and traceability of the resources used,</p><p>operations performed and results</p><p>obtained while using the platform.</p>',
    pt: '<p>eyesCloud3D utiliza o protocolo IOTA Tangle para</p><p>obter os mais elevados padrões de segurança dos</p><p>dados dos nossos utilizadores, bem como a fiabilidade,</p><p>integridade e rastreabilidade dos recursos utilizados,</p><p>das operações realizadas e dos resultados</p><p>obtidos durante a utilização da plataforma.</p>',
    fr: '<p>eyesCloud3D utilise le protocole IOTA Tangle pour</p><p>garantir les meilleurs niveaux de sécurité des</p><p>données de nos utilisateurs, tout comme la fiabilité,</p><p>l’intégrité et la traçabilité des ressources employées,</p><p>des opérations réalisées et des résultats obtenus</p><p>pendant l’utilisation de la plateforme.</p>',
    eus: '<p>eyesCloud3D-k IOTA Tangle protokoloa erabiltzen du</p><p>gure erabiltzaileen datuen segurtasun- estandar</p><p>handienak bermatzeko, bai eta erabilitako baliabideen,</p><p>gauzatutako ekintzen eta plataforma erabiltzean</p><p>lortutako emaitzen fidagarritasuna, osotasuna</p><p>eta trazabilitatea bermatzeko ere.</p>',
    cat: '<p>eyesCloud3D utilitza el protocol IOTA Tangle per</p><p>obtenir els millors estàndards de seguretat de</p><p>les dades dels nostres usuaris, així com la fiabilitat,</p><p>integritat i traçabilitat dels recursos emprats, les</p><p>operacions efectuades i els resultats obtinguts</p><p>durant l’ús de la plataforma.</p>',
  },

  iota_Dialog_Data5: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },
};
