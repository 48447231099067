import { Component } from '@angular/core';

@Component({
  selector: 'app-model-not-found',
  templateUrl: './model-not-found.component.html',
  styleUrls: ['./model-not-found.component.scss']
})
export class ModelNotFoundComponent {

}
