import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Searchable } from '../../../../commons/models/searchable';
import { User } from '../../../../commons/models/user/user';
import { Retrievable } from '../../../../commons/models/retrievable';
import { UserSearchCriteria } from '../../../../commons/models/user/user-search-criteria';
import { Saveable } from '../../../../commons/models/saveable';
import { Deleteable } from '../../../../commons/models/deleteable';
import { RestClientService } from '../../api-access/rest-client.service';
import { RequestToBeACompany } from '../../../../new-3d/models/request-to-be-a-company';
import { ChangePasswordRequest } from '../../../../profile/models/change-password-request';
import { FirstExpData } from 'src/app/core/models/user/first-exp';

type UserRegisterData = {
  email: string;
  password: string;
  profile: { name: string };
};

@Injectable({
  providedIn: 'root',
})
export class UserRemoteService
  implements
    Searchable<User, UserSearchCriteria>,
    Retrievable<User, number>,
    Saveable<User>,
    Deleteable<User, number>
{
  private URL = '/users';
  private URL3 = '/interests';

  constructor(private restClientService: RestClientService) {}

  public search(criteria?: UserSearchCriteria): Observable<User[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  public get(id: number): Observable<User> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }

  public getDepartmentUsers(id: number): Observable<User[]> {
    return this.restClientService.get(`${this.URL}/${id}/department-users`);
  }

  public save(user: User): Observable<any> {
    return this.restClientService.put(`${this.URL}/${user.id}`, user);
  }

  public register(data: UserRegisterData): Observable<any> {
    return this.restClientService.post('register', data);
  }

  public delete(id: number): Observable<User> {
    return this.restClientService.delete(`${this.URL}/${id}`);
  }

  public resetPassword(id: number, password: string): Observable<User> {
    return this.restClientService.put(`${this.URL}/${id}/password`, {
      password,
      id,
    });
  }

  public updatePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<User> {
    return this.restClientService.put(`${this.URL}/password`, {
      currentPassword,
      newPassword,
    });
  }

  public forgotPassword(login: string, confirmationLink: string) {
    return this.restClientService.put(`${this.URL}/forgot-password/${login}`, {
      confirmationLink,
    });
  }

  public uploadAvatar(userId: number, avatar: string): Observable<string> {
    return this.restClientService.post(`${this.URL}/${userId}/avatar`, {
      avatar,
    });
  }

  public getAvatar(userId: number): Observable<Blob> {
    return this.restClientService.getBlob(`${this.URL}/${userId}/avatar`, null);
  }

  public changePassword(
    user: User,
    changePasswordRequest: ChangePasswordRequest
  ) {
    return this.restClientService.put(
      `${this.URL}/${user.id}/change-password`,
      changePasswordRequest
    );
  }
  public changeLanguage(language: string): Observable<null> {
    return this.restClientService.put('change-language', {
      language,
    });
  }
  public sendRequestToBeACompany(
    request: RequestToBeACompany
  ): Observable<any> {
    return this.restClientService.post(
      `${this.URL}/request-to-be-company`,
      request
    );
  }

  public editLanguage(userId: number, data: any): Observable<any> {
    return this.restClientService.put(`${this.URL}/${userId}/language`, data);
  }

  public getUserByEmail(email: string): Observable<any> {
    return this.restClientService.get(`${this.URL}/byemail/${email}`);
  }
  public getInterests(): Observable<any> {
    return this.restClientService.get(`${this.URL3}`);
  }

  public changeDepartment(
    userId: number,
    newDepartment: any
  ): Observable<User[]> {
    return this.restClientService.put(
      `${this.URL}/${userId}/change-user-department`,
      newDepartment
    );
  }

  public getRoles(): Observable<any[]> {
    return this.restClientService.get('/roles');
  }

  public getFirstExpInfo(): Observable<FirstExpData> {
    return this.restClientService.get(`${this.URL}/first-exp`);
  }

  public editFirstExpInfo(data: any): Observable<any> {
    return this.restClientService.put(`${this.URL}/first-exp`, data);
  }
}
