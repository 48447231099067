import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, } from '@angular/material/legacy-dialog';
import { AddHyperspectralDialogComponent } from '../add-hyperspectral-dialog/add-hyperspectral-dialog.component';

@Component({
  selector: 'app-img-uploaded-dialog',
  templateUrl: './img-uploaded-dialog.component.html',
  styleUrls: ['./img-uploaded-dialog.component.scss']
})
export class ImgUploadedDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImgUploadedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  close(retry: boolean = false): void {
    if (!this.data?.success && retry) {
      this.dialog.open(AddHyperspectralDialogComponent, {
        maxWidth: '100vw',
        data: this.data?.model,
        autoFocus: false,
        panelClass: 'addHyperspectral',
      });
    }
    this.dialogRef.close();
  }
}
