import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Language } from 'src/app/models/language';
import { AppConfigService } from '../../../services/config/app-config.service';
import { ResponsiveService } from '../../../core/services/responsive.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { CommsService } from 'src/app/core/services/comms.service';
import { InformationPageEnum } from 'src/app/components/information-text/enum/information-page-enum';
import { TranslationModeEnum } from '../../enum/translation-mode-enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  languageSelected: Language;
  engSelected = false;
  isPublicApp = true;
  isMobile =
    this.responsiveService.newMobile() && !this.responsiveService.newTablet();
  mireia = false;

  userName: any;
  userNameLetter: any;

  profileLink: any;

  informationPageEnum = InformationPageEnum;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  @ViewChild('scrollableFooter', { static: false }) scrollableFooter: ElementRef;

  constructor(
    private i18nService: I18nService,
    private appConfigService: AppConfigService,
    private authenticationService: AuthenticationService,
    public responsiveService: ResponsiveService,
    private router: Router,
    private eventService: EventService,
    private commsService: CommsService
  ) { }

  ngOnInit() {
    this.languageSelected = this.i18nService.getCurrentLanguage();
    if (this.languageSelected.code === 'en') {
      this.engSelected = true;
    }
    this.isPublicApp = environment.isPublicApp;
    // if (!this.router.url.includes('new3d/load')) {
    //   this.authenticationService.get().subscribe((userLogged) => {});
    // }
    this.commsService.getIsMireia().subscribe((value) => {
      this.mireia = value;
    });

    this.commsService.getCurrentUser().subscribe((user) => {
      this.userName = user.profile?.name + ' ' + user.profile?.surname;
      this.userNameLetter = user.profile?.name.charAt(0);
    });

    this.checkProfileLink();

    document.body.addEventListener('touchmove', this.handleTouchMove, {
      passive: false,
    });
  }

  ngOnDestroy(): void {
    document.body.removeEventListener('touchmove', this.handleTouchMove);
  }

  /**
   * Se ejecuta al redimensionar la ventana
   */
  onResize() {
    this.isMobile =
      this.responsiveService.newMobile() && !this.responsiveService.newTablet();
    this.checkProfileLink();
  }

  /**
   * Revisa si te lleva al perfil de móvil o de PC
   */
  checkProfileLink() {
    if (this.responsiveService.newMobile() && !this.responsiveService.specialMovilResolution()) {
      this.profileLink = '/profile/mobile-user-profile-menu';
    } else {
      console.log('Entrada a pc');
      this.profileLink = '/profile/personal-data';
    }
  }

  changeText(informationPageNumber) {
    // Obtén todos los elementos con la clase "active"
    const activeElements = document.querySelectorAll('.info-text-active');

    // Elimina la clase "active" de todos los elementos
    activeElements.forEach(element => {
      element.classList.remove('info-text-active');
    });

    // Según el argumento recibido, muestra el texto correcto
    if (informationPageNumber === 3) {
      document.querySelector('.cookie-policy')?.classList.add('info-text-active');
    } else if (informationPageNumber === 2) {
      document.querySelector('.privacy-policy')?.classList.add('info-text-active');
    } else {
      document.querySelector('.terms-of-use')?.classList.add('info-text-active');
    }
  }


  private handleTouchMove = (e: TouchEvent) => {
    const target = e.target as HTMLElement;
    const isScrollable = target.scrollHeight > target.clientHeight;
    if (!isScrollable && this.scrollableFooter.nativeElement.contains(target)) {
      e.preventDefault();
    }
  };
}
