export const RUN_OVER_I18N_ENTRIES = {
  run_over_Data1: {
    es: '<p>Análisis de Atropello</p>',
    en: '<p>Pedestrian Impact</p>',
    pt: '<p>Análise do atropelamento</p>',
    fr: '<p>Analyse de la collision</p>',
    eus: '<p>Harrapatze azterketa</p>',
    cat: '<p>Anàlisi d\'atropellament</p>',
  },

  run_over_Data2: {
    es: '<p>ACTIVAR HERRAMIENTA</p>',
    en: '<p>ACTIVATE TOOL</p>',
    pt: '<p>ATIVAR FERRAMENTA</p>',
    fr: '<p>ACTIVER L\'OUTIL</p>',
    eus: '<p>AKTIBATU TRESNA</p>',
    cat: '<p>ACTIVA L’EINA</p>',
  },

  run_over_Data3: {
    es: '<p>HERRAMIENTA ACTIVADA</p>',
    en: '<p>TOOL ACTIVATED</p>',
    pt: '<p>FERRAMENTA ATIVADA</p>',
    fr: '<p>OUTIL ACTIVÉ</p>',
    eus: '<p>TRESNA AKTIBATUTA</p>',
    cat: '<p>EINA ACTIVADA</p>',
  },

  run_over_Data4: {
    es: '<p>Selecciona el cálculo que deseas</p><p>realizar en función de las variables de</p><p>las que dispongas.</p>',
    en: '<p>Select the calculation you want</p><p>to perform according to the variables</p><p>you have available.</p>',
    pt: '<p>Selecione o cálculo que pretende</p><p>efetuar de acordo com as variáveis</p><p>que tem à sua disposição.</p>',
    fr: '<p>Sélectionnez le calcul que vous souhaitez</p><p>effectuer en fonction des variables dont</p><p>vous disposez.</p>',
    eus: '<p>Aukeratu zer kalkulu</p><p>burutu nahi duzun, ezagutzen dituzun</p><p>aldagaien arabera.</p>',
    cat: '<p>Selecciona quin càlcul</p><p>vols fer en funció de les variables</p><p>de què disposis.</p>',
  },

  run_over_Data5: {
    es: '<p>Historial de cálculos</p>',
    en: '<p>Calculation record</p>',
    pt: '<p>Histórico de cálculos</p>',
    fr: '<p>Historique de calculs</p>',
    eus: '<p>Kalkuluen historia</p>',
    cat: '<p>Historial de càlculs</p>',
  },

  run_over_Data6: {
    es: '<p>‘’ Este modelo 3D no tiene</p><p>cálculos asignados ‘’</p>',
    en: '<p>“This 3D model has no</p><p>calculations assigned”</p>',
    pt: '<p>"Este modelo 3D não tem</p><p>cálculos atribuídos"</p>',
    fr: '<p>« Ce modèle 3D ne dispose pas</p><p>de calculs assignés »</p>',
    eus: '<p>"3D eredu honetan ez</p><p>da kalkulurik ezarri"</p>',
    cat: '<p>“Aquest model 3D no té</p><p>càlculs assignats”</p>',
  },

  run_over_Data7: {
    es: '<p>Reconstrucción</p>',
    en: '<p>Reconstruction</p>',
    pt: '<p>Reconstrução</p>',
    fr: '<p>Reconstitution</p>',
    eus: '<p>Berreraikuntza</p>',
    cat: '<p>Reconstrucció</p>',
  },

  run_over_Data8: {
    es: '<p>Simulación</p>',
    en: '<p>Simulation</p>',
    pt: '<p>Simulação</p>',
    fr: '<p>Simulation</p>',
    eus: '<p>Simulazioa</p>',
    cat: '<p>Simulació</p>',
  },

  run_over_Data9: {
    es: '<p>Ver</p>',
    en: '<p>View</p>',
    pt: '<p>Ver</p>',
    fr: '<p>Voir</p>',
    eus: '<p>Ikusi</p>',
    cat: '<p>Mostra</p>',
  },

  run_over_Data10: {
    es: '<p>Reconstrucción de accidente</p>',
    en: '<p>Accident reconstruction</p>',
    pt: '<p>Reconstrução do acidente</p>',
    fr: '<p>Reconstitution d’un accident</p>',
    eus: '<p>Istripuaren berreraikuntza</p>',
    cat: '<p>Reconstrucció d’accident</p>',
  },

  run_over_Data11: {
    es: '<p>Selecciona el punto final (coincide con el</p><p>peatón atropellado) y el punto inicial</p><p>(impacto del vehículo con el peatón)</p>',
    en: '<p>Select the final point (coincides with the</p><p>struck pedestrian) and the initial point</p><p>(where the vehicle struck the pedestrian)</p>',
    pt: '<p>Selecione o ponto final (coincide com o</p><p>atropelamento do peão) e o ponto inicial</p><p>(impacto do veículo com o peão)</p>',
    fr: '<p>Sélectionnez le point final (coïncide avec le</p><p>piéton renversé) et le point initial</p><p>(impact du véhicule avec le piéton)</p>',
    eus: '<p>Aukeratu bukaerako puntua (harrapatutako oinezkoarekin</p><p>kointziditzen du) eta hasierako puntua (ibilgailuaren</p><p>eta oinezkoaren arteko inpaktua)</p>',
    cat: '<p>Selecciona el punt final (coincideix</p><p>amb el vianant atropellat) i el punt inicial</p><p>(impacte del vehicle amb el vianant)</p>',
  },

  run_over_Data12: {
    es: '<p>Punto inicial</p>',
    en: '<p>Initial point</p>',
    pt: '<p>Ponto inicial</p>',
    fr: '<p>Point initial</p>',
    eus: '<p>Hasierako puntua</p>',
    cat: '<p>Punt inicial</p>',
  },

  run_over_Data13: {
    es: '<p>Punto final</p>',
    en: '<p>Final point</p>',
    pt: '<p>Ponto final</p>',
    fr: '<p>Point final</p>',
    eus: '<p>Amaierako puntua</p>',
    cat: '<p>Punt final</p>',
  },

  run_over_Data14: {
    es: '<p>Rehacer</p>',
    en: '<p>Redo</p>',
    pt: '<p>Refazer</p>',
    fr: '<p>Refaire</p>',
    eus: '<p>Berregin</p>',
    cat: '<p>Refés</p>',
  },

  run_over_Data15: {
    es: '<p>Selecciona el tipo de vehículo con el</p><p>que estás trabajando.</p>',
    en: '<p>Select the type of vehicle</p><p>you are working with.</p>',
    pt: '<p>Selecione o tipo de veículo</p><p>com que está a trabalhar.</p>',
    fr: '<p>Sélectionnez le type de véhicule avec lequel</p><p>vous travaillez.</p>',
    eus: '<p>Aukeratu zer ibilgailu motarekin</p><p>ari zaren lanean.</p>',
    cat: '<p>Selecciona el tipus de vehicle amb què</p><p>estàs treballant.</p>',
  },

  run_over_Data16: {
    es: '<p>Marcar esta casilla si el atropellad@ es un niñ@</p>',
    en: '<p>Check this box if the struck pedestrian is a child</p>',
    pt: '<p>Marque esta caixa se a pessoa atropelada é uma criança</p>',
    fr: '<p>Cochez cette case si le piéton renversé est un enfant</p>',
    eus: '<p>Markatu laukitxo hau oinezkoa haurra bada</p>',
    cat: '<p>Marca aquesta casella si la persona atropellada és un/a nen/a</p>',
  },

  run_over_Data17: {
    es: '<p>Velocidad del vehículo (km/h):</p>',
    en: '<p>Vehicle speed (km/h):</p>',
    pt: '<p>Velocidade do veículo (km/h):</p>',
    fr: '<p>Vitesse du véhicule (km/h) :</p>',
    eus: '<p>Ibilgailuaren abiadura (km/h):</p>',
    cat: '<p>Velocitat del vehicle (km/h):</p>',
  },

  run_over_Data18: {
    es: '<p>Calcular</p>',
    en: '<p>Calculate</p>',
    pt: '<p>Calcular</p>',
    fr: '<p>Calculer</p>',
    eus: '<p>Kalkulatu</p>',
    cat: '<p>Calcula</p>',
  },

  run_over_Data19: {
    es: '<p>Resetear cálculo</p>',
    en: '<p>Reset calculation</p>',
    pt: '<p>Redefinir cálculo</p>',
    fr: '<p>Réinitialiser calcul</p>',
    eus: '<p>Berrezarri kalkulua</p>',
    cat: '<p>Restableix el càlcul</p>',
  },

  run_over_Data20: {
    es: '<p>Eliminar cálculo guardado</p>',
    en: '<p>Delete saved calculation</p>',
    pt: '<p>Eliminar cálculo guardado</p>',
    fr: '<p>Supprimer le calcul sauvegardé</p>',
    eus: '<p>Ezabatu gordetako kalkulua</p>',
    cat: '<p>Elimina el càlcul desat</p>',
  },

  run_over_Data21: {
    es: '<p>Has seleccionado eliminar el cálculo de atropello guardado</p><p>con el nombre: %X%</p>',
    en: '<p>You have selected to delete the impact calculation saved</p><p>under the name: %X%</p>',
    pt: '<p>Selecionou eliminar o cálculo de atropelamento guardado</p><p>com o nome: %X%</p>',
    fr: '<p>Vous avez choisi de supprimer le calcul de collision sauvegardé</p><p>sous le nom : %X%</p>',
    eus: '<p>Honako izena duen harrapatze-kalkulua</p><p>ezabatzea aukeratu duzu: %X%</p>',
    cat: '<p>Has seleccionat eliminar el càlcul d\'atropellament desat</p><p>amb el nom: %X%</p>',
  },

  run_over_Data22: {
    es: '<p>¿Deseas eliminar el cálculo guardado?</p>',
    en: '<p>Do you want to delete the saved calculation?</p>',
    pt: '<p>Pretende eliminar o cálculo guardado?</p>',
    fr: '<p>Souhaitez-vous supprimer le calcul sauvegardé ?</p>',
    eus: '<p>Gordetako kalkulua ezabatu nahi duzu?</p>',
    cat: '<p>Vols eliminar el càlcul desat?</p>',
  },

  run_over_Data23: {
    es: '<p>Eliminar</p>',
    en: '<p>Delete</p>',
    pt: '<p>Eliminar</p>',
    fr: '<p>Supprimer</p>',
    eus: '<p>Ezabatu</p>',
    cat: '<p>Elimina</p>',
  },

  run_over_Data24: {
    es: '<p>Velocidad de impacto:</p>',
    en: '<p>Impact velocity:</p>',
    pt: '<p>Velocidade de impacto:</p>',
    fr: '<p>Vitesse d\'impact :</p>',
    eus: '<p>Inpaktuaren abiadura:</p>',
    cat: '<p>Velocitat de l’impacte:</p>',
  },

  run_over_Data25: {
    es: '<p>Distancia de proyección:</p>',
    en: '<p>Projection distance:</p>',
    pt: '<p>Distância de projeção:</p>',
    fr: '<p>Distance de projection :</p>',
    eus: '<p>Proiekzio-distantzia:</p>',
    cat: '<p>Distància de projecció:</p>',
  },

  run_over_Data26: {
    es: '<p>Nombre para el cálculo de atropello:</p>',
    en: '<p>Name for impact calculation:</p>',
    pt: '<p>Nome para o cálculo de atropelamento:</p>',
    fr: '<p>Nom du calcul de collision :</p>',
    eus: '<p>Harrapatze-kalkuluaren izena:</p>',
    cat: '<p>Nom per al càlcul d\'atropellament:</p>',
  },

  run_over_Data27: {
    es: '<p>.Appel</p>',
    en: '<p>.Appel</p>',
    pt: '<p>.Appel</p>',
    fr: '<p>.Appel</p>',
    eus: '<p>.Appel</p>',
    cat: '<p>.Appel</p>',
  },

  run_over_Data28: {
    es: '<p>Guardar cálculos</p>',
    en: '<p>Save calculations</p>',
    pt: '<p>Guardar cálculos</p>',
    fr: '<p>Sauvegarder calculs</p>',
    eus: '<p>Gorde kalkuluak</p>',
    cat: '<p>Desa els càlculs</p>',
  },

  run_over_Data29: {
    es: '<p>Cálculo de Appel guardado</p><p>correctamente</p>',
    en: '<p>Appel calculation saved</p><p>correctly</p>',
    pt: '<p>Cálculo de Appel guardado</p><p>corretamente</p>',
    fr: '<p>Calcul d’Appel sauvegardé</p><p>correctement</p>',
    eus: '<p>Appel kalkulua</p><p>ondo gorde da</p>',
    cat: '<p>El càlcul d’Appel</p><p>s’ha desat correctament</p>',
  },

  run_over_Data30: {
    es: '<p>Descargar informes</p>',
    en: '<p>Download reports</p>',
    pt: '<p>Transferir relatórios</p>',
    fr: '<p>Télécharger rapports</p>',
    eus: '<p>Deskargatu txostenak</p>',
    cat: '<p>Baixa els informes</p>',
  },

  run_over_Data31: {
    es: '<p>.Searle</p>',
    en: '<p>.Searle</p>',
    pt: '<p>.Searle</p>',
    fr: '<p>.Searle</p>',
    eus: '<p>.Searle</p>',
    cat: '<p>.Searle</p>',
  },

  run_over_Data32: {
    es: '<p>PDF</p>',
    en: '<p>PDF</p>',
    pt: '<p>PDF</p>',
    fr: '<p>PDF</p>',
    eus: '<p>PDF</p>',
    cat: '<p>PDF</p>',
  },

  run_over_Data33: {
    es: '<p>Opciones Avanzadas</p>',
    en: '<p>Advanced Options</p>',
    pt: '<p>Opções avançadas</p>',
    fr: '<p>Options avancées</p>',
    eus: '<p>Aukera aurreratuak</p>',
    cat: '<p>Opcions avançades</p>',
  },

  run_over_Data34: {
    es: '<p>Opciones Avanzadas</p>',
    en: '<p>Advanced Options</p>',
    pt: '<p>Opções avançadas</p>',
    fr: '<p>Options avancées</p>',
    eus: '<p>Aukera aurreratuak</p>',
    cat: '<p>Opcions avançades</p>',
  },

  run_over_Data35: {
    es: '<p>Coeficiente de fricción:</p>',
    en: '<p>Friction coefficient:</p>',
    pt: '<p>Coeficiente de fricção:</p>',
    fr: '<p>Coefficient de friction :</p>',
    eus: '<p>Marruskadura-koefizientea:</p>',
    cat: '<p>Coeficient de fricció:</p>',
  },

  run_over_Data36: {
    es: '<p>Pendiente:</p>',
    en: '<p>Gradient:</p>',
    pt: '<p>Pendente:</p>',
    fr: '<p>Pente :</p>',
    eus: '<p>Malda:</p>',
    cat: '<p>Pendent:</p>',
  },

  run_over_Data37: {
    es: '<p>Calcular searle</p>',
    en: '<p>Calculate Searle</p>',
    pt: '<p>Calcular searle</p>',
    fr: '<p>Calculer Searle</p>',
    eus: '<p>Kalkulatu Searle</p>',
    cat: '<p>Calcula searle</p>',
  },

  run_over_Data38: {
    es: '<p>Resetear cálculo</p>',
    en: '<p>Reset calculation</p>',
    pt: '<p>Redefinir cálculo</p>',
    fr: '<p>Réinitialiser calcul</p>',
    eus: '<p>Berrezarri kalkulua</p>',
    cat: '<p>Restableix el càlcul</p>',
  },

  run_over_Data39: {
    es: '<p>Velocidad de impacto:</p>',
    en: '<p>Impact velocity:</p>',
    pt: '<p>Velocidade de impacto:</p>',
    fr: '<p>Vitesse d\'impact :</p>',
    eus: '<p>Inpaktuaren abiadura:</p>',
    cat: '<p>Velocitat de l’impacte:</p>',
  },

  run_over_Data40: {
    es: '<p>Nombre para el cálculo de atropello:</p>',
    en: '<p>Name for impact calculation:</p>',
    pt: '<p>Nome para o cálculo de atropelamento:</p>',
    fr: '<p>Nom du calcul de collision :</p>',
    eus: '<p>Harrapatze-kalkuluaren izena:</p>',
    cat: '<p>Nom per al càlcul d\'atropellament:</p>',
  },

  run_over_Data41: {
    es: '<p>Guardar cálculos</p>',
    en: '<p>Save calculations</p>',
    pt: '<p>Guardar cálculos</p>',
    fr: '<p>Sauvegarder calculs</p>',
    eus: '<p>Gorde kalkuluak</p>',
    cat: '<p>Desa els càlculs</p>',
  },

  run_over_Data42: {
    es: '<p>Cálculo de Searle guardado</p><p>correctamente</p>',
    en: '<p>Searle calculation saved</p><p>correctly</p>',
    pt: '<p>Cálculo de Searle guardado</p><p>corretamente</p>',
    fr: '<p>Calcul de Searle sauvegardé</p><p>correctement</p>',
    eus: '<p>Searle-kalkulua</p><p>ondo gorde da</p>',
    cat: '<p>El càlcul de Searle</p><p>s’ha desat correctament</p>',
  },

  run_over_Data43: {
    es: '<p>Cálculos</p>',
    en: '<p>Calculations</p>',
    pt: '<p>Cálculos</p>',
    fr: '<p>Calculs</p>',
    eus: '<p>Kalkuluak</p>',
    cat: '<p>Càlculs</p>',
  },

  run_over_Data44: {
    es: '<p>Selecciona el blueprint</p>',
    en: '<p>Select the blueprint</p>',
    pt: '<p>Selecionar blueprint</p>',
    fr: '<p>Sélectionnez le blueprint</p>',
    eus: '<p>Aukeratu blueprint-a</p>',
    cat: '<p>Selecciona el blueprint</p>',
  },

  run_over_Data45: {
    es: '<p>Selecciona el tipo de vehículo con el que</p><p>quieras trabajar.</p>',
    en: '<p>Select the type of vehicle you</p><p>want to work with.</p>',
    pt: '<p>Selecione o tipo de veículo com que</p><p>pretende trabalhar.</p>',
    fr: '<p>Sélectionnez le type de véhicule avec lequel</p><p>vous souhaitez travailler.</p>',
    eus: '<p>Aukeratu zer ibilgailu motarekin</p><p>lan egin nahi duzun.</p>',
    cat: '<p>Selecciona el tipus de vehicle amb què</p><p>vulguis treballar.</p>',
  },

  run_over_Data46: {
    es: '<p>Incorporar blueprint</p>',
    en: '<p>Include blueprint</p>',
    pt: '<p>Incorporar blueprint</p>',
    fr: '<p>Intégrer blueprint</p>',
    eus: '<p>Erantsi blueprint-a</p>',
    cat: '<p>Incorpora el blueprint</p>',
  },

  run_over_Data47: {
    es: '<p>Alinear blueprint</p>',
    en: '<p>Align blueprint</p>',
    pt: '<p>Alinhar blueprint</p>',
    fr: '<p>Aligner blueprint</p>',
    eus: '<p>Lerrokatu blueprint-a</p>',
    cat: '<p>Alinea el blueprint</p>',
  },

  run_over_Data48: {
    es: '<p>Primero coloca el modelo 3D del coche</p><p>sobre la base del Blueprint con los botones</p><p>de movimiento.</p><p>Después coloca la altura del Blueprint a</p><p>la altura del vehículo.</p>',
    en: '<p>First place the car’s 3D model</p><p>on the blueprint base with the</p><p>movement buttons.</p><p>Next place the blueprint over the vehicle</p><p>keeping them at the same height.</p>',
    pt: '<p>Primeiro, coloque o modelo 3D do carro</p><p>sobre a base do Blueprint com os botões</p><p>de movimento.</p><p>Em seguida, coloque a altura do Blueprint à</p><p>altura do veículo.</p>',
    fr: '<p>Tout d’abord, placez le modèle 3D de la voiture</p><p>sur la base du blueprint à l’aide des boutons</p><p>de mouvement.</p><p>Ensuite, placez la hauteur du blueprint à</p><p>la hauteur du véhicule.</p>',
    eus: '<p>Lehenik, kokatu ibilgailuaren 3D eredua</p><p>blueprint-aren oinarriaren gainean</p><p>mugimendu botoiak erabiliz.</p><p>Ondoren, mugitu blueprint-aren altuera</p><p>autoaren altuerarekin bat etor dadin</p>',
    cat: '<p>Primerament, col·loca el model 3D del cotxe</p><p>sobre la base del Blueprint amb els</p><p>botons de moviment.</p><p>Després, col·loca l’alçària del Blueprint a</p><p>l’altura del vehicle.</p>',
  },

  run_over_Data49: {
    es: '<p>Confirmar alineación</p>',
    en: '<p>Confirm alignment</p>',
    pt: '<p>Confirmar alinhamento</p>',
    fr: '<p>Confirmer alignement</p>',
    eus: '<p>Berretsi lerrokatzea</p>',
    cat: '<p>Confirma l’alineació</p>',
  },

  run_over_Data50: {
    es: '<p>Modificar alineación</p>',
    en: '<p>Modify alignment</p>',
    pt: '<p>Modificar alinhamento</p>',
    fr: '<p>Modifier alignement</p>',
    eus: '<p>Aldatu lerrokatzea</p>',
    cat: '<p>Modifica l’alineació</p>',
  },

  run_over_Data51: {
    es: '<p>Simulación de accidente</p>',
    en: '<p>Accident simulation</p>',
    pt: '<p>Simulação de acidente</p>',
    fr: '<p>Simulation d’accident</p>',
    eus: '<p>Istripuaren simulazioa</p>',
    cat: '<p>Simulació d’accident</p>',
  },
};

