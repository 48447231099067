import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { StorageService } from 'src/app/models/storage/storage.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-iota-notice',
  templateUrl: './iota-notice.component.html',
  styleUrls: ['./iota-notice.component.scss'],
})
export class IotaNoticeComponent implements OnInit {
  is1194 = this.responsiveService.moreThan1194();

  constructor(
    private storageService: StorageService,
    private responsiveService: ResponsiveService,
    public dialog: MatDialogRef<IotaNoticeComponent>,
  ) { }

  ngOnInit() {
  }

  acceptIOTA() {
    this.storageService.set('IotaRead', '1');
    this.dialog.close();
  }

  onResize() {
    this.is1194 = this.responsiveService.moreThan1194();
  }
}
