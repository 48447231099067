import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  FirstExperienceCloseEnum,
  FirstExperienceGalleryStepsEnum,
  FirstExperienceLoadStepsEnum,
  FirstExperienceNewModelStepsEnum,
  FirstExperienceViewprojectStepsEnum,
} from 'src/app/commons/enum/first-experience-enum';
import { FirstExperienceHomeDialogComponent } from 'src/app/components/dialogs/first-experience-dialogs/first-experience-home-dialog/first-experience-home-dialog.component';
import { FirstExpData } from 'src/app/core/models/user/first-exp';
import { UserRemoteService } from './user.remote.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { FirstExperienceGalleryDialogComponent } from 'src/app/components/dialogs/first-experience-dialogs/first-experience-gallery-dialog/first-experience-gallery-dialog.component';
import { FirstExperienceLoadDialogComponent } from 'src/app/components/dialogs/first-experience-dialogs/first-experience-load-dialog/first-experience-load-dialog.component';
import { FirstExperienceNewModelDialogComponent } from 'src/app/components/dialogs/first-experience-dialogs/first-experience-new-model-dialog/first-experience-new-model-dialog.component';
import { FirstExperienceViewprojectDialogComponent } from 'src/app/components/dialogs/first-experience-dialogs/first-experience-viewproject-dialog/first-experience-viewproject-dialog.component';
import { CommsService } from '../../comms.service';
import { User } from 'src/app/commons/models/user/user';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { StorageService } from 'src/app/models/storage/storage.service';
// import { NcConsentComponent } from 'src/app/commons/components/cookie-consent/nc-consent.component';
import { IotaNoticeComponent } from 'src/app/commons/components/iota-notice/iota-notice.component';

@Injectable({
  providedIn: 'root',
})
export class FirstExpService {
  firstExpData: FirstExpData;
  user: User;
  mutex: boolean = false;
  routeChanges: Subscription;

  constructor(
    private userRemoteService: UserRemoteService,
    private dialog: MatDialog,
    private router: Router,
    private commsService: CommsService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService
  ) {
    this.commsService.getCurrentUser().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.loadFirstExpData();
        this.listenForRouteChanges();
      }
    });
    this.authenticationService.onLogout().subscribe(() => {
      this.user = null;
    });
  }

  private listenForRouteChanges() {
    if (this.routeChanges) {
      this.routeChanges.unsubscribe();
    }
    this.routeChanges = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.checkScreenStatus(route.url);
      }
    });
  }

  private loadFirstExpData() {
    if (!this.mutex) {
      this.mutex = true;
      this.userRemoteService.getFirstExpInfo().subscribe(
        (response) => {
          this.mutex = false;
          this.firstExpData = response;
          this.checkScreenStatus();
        },
        () => {
          this.mutex = false;
        }
      );
    }
  }

  public markAllScreensAsSeen() {
    this.saveFirstExpInfo({
      home: true,
      gallery: true,
      load: true,
      new: true,
      viewproject: true,
    }).subscribe(() => {});
  }

  public checkScreenStatus(url: string = this.router.url) {
    if (this.firstExpData) {
      this.checkShouldOpenDialog(url);
    } else {
      this.loadFirstExpData();
    }
  }

  private checkShouldOpenDialog(url: string) {
    if (url === '/') {
      if (!this.storageService.get('cookiesRead')) {
        this.openCookiesDialog();
      } else {
        if (!this.firstExpData.home) {
          if (
            this.dialog.openDialogs.findIndex(
              (dialog) =>
                dialog._containerInstance._config.panelClass ===
                'FirstExperienceHomeDialog'
            ) === -1
          ) {
            this.openHomeDialog();
          }
        }
      }
    } else if (url === '/gallery') {
      if (!this.firstExpData.gallery) {
        if (
          this.dialog.openDialogs.findIndex(
            (dialog) =>
              dialog._containerInstance._config.panelClass ===
              'FirstExperienceGalleryDialog'
          ) === -1
        ) {
          this.openGalleryDialog();
        }
      }
    } else if (url === '/new3d') {
      if (!this.storageService.get('IotaRead')) {
        this.openIotaDialog();
      } else {
        if (!this.firstExpData.load) {
          if (
            this.dialog.openDialogs.findIndex(
              (dialog) =>
                dialog._containerInstance._config.panelClass ===
                'FirstExperienceLoadDialog'
            ) === -1
          ) {
            this.openLoadDialog();
          }
        }
      }
    } else if (url === '/new3d/load') {
      if (!this.firstExpData.new) {
        if (
          this.dialog.openDialogs.findIndex(
            (dialog) =>
              dialog._containerInstance._config.panelClass ===
              'FirstExperienceNewModelDialog'
          ) === -1
        ) {
          this.openNewModelDialog();
        }
      }
    } else if (url.includes('/model/')) {
      if (!this.firstExpData.viewproject) {
        if (
          this.dialog.openDialogs.findIndex(
            (dialog) =>
              dialog._containerInstance._config.panelClass ===
              'FirstExperienceViewprojectDialog'
          ) === -1
        ) {
          this.openViewprojectDialog();
        }
      }
    }
  }

  public saveFirstExpInfo(info: FirstExpData): Observable<null> {
    return new Observable((subscriber) => {
      this.userRemoteService.editFirstExpInfo(info).subscribe({
        next: () => {
          this.firstExpData = info;
          subscriber.next();
          subscriber.complete();
        },
        error: () => {},
      });
    });
  }

  private openHomeDialog(): void {
    this.dialog
      .open(FirstExperienceHomeDialogComponent, {
        panelClass: 'FirstExperienceHomeDialog',
        backdropClass: 'FirstExperienceBackdrop',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response: FirstExperienceCloseEnum) => {
        if (response === FirstExperienceCloseEnum.SKIP) {
          this.markAllScreensAsSeen();
        } else if (response === FirstExperienceCloseEnum.LAST) {
          this.firstExpData.home = true;
          this.saveFirstExpInfo(this.firstExpData).subscribe(() => {
            this.router.navigateByUrl('/new3d');
          });
        }
      });
  }

  private openGalleryDialog(): void {
    this.dialog
      .open(FirstExperienceGalleryDialogComponent, {
        panelClass: 'FirstExperienceGalleryDialog',
        backdropClass: 'FirstExperienceBackdrop',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === FirstExperienceGalleryStepsEnum.LAST) {
          this.firstExpData.gallery = true;
          this.saveFirstExpInfo(this.firstExpData).subscribe(() => {});
        }
      });
  }

  private openLoadDialog(): void {
    this.dialog
      .open(FirstExperienceLoadDialogComponent, {
        panelClass: 'FirstExperienceLoadDialog',
        backdropClass: 'FirstExperienceBackdrop',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === FirstExperienceLoadStepsEnum.LAST) {
          this.firstExpData.load = true;
          this.saveFirstExpInfo(this.firstExpData).subscribe(() => {});
        }
      });
  }

  private openNewModelDialog(): void {
    this.dialog
      .open(FirstExperienceNewModelDialogComponent, {
        panelClass: 'FirstExperienceNewModelDialog',
        backdropClass: 'FirstExperienceBackdrop',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === FirstExperienceNewModelStepsEnum.LAST) {
          this.firstExpData.new = true;
          this.saveFirstExpInfo(this.firstExpData).subscribe(() => {});
        }
      });
  }

  private openViewprojectDialog(): void {
    this.dialog
      .open(FirstExperienceViewprojectDialogComponent, {
        panelClass: 'FirstExperienceViewprojectDialog',
        backdropClass: 'FirstExperienceBackdrop',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === FirstExperienceViewprojectStepsEnum.LAST) {
          this.firstExpData.viewproject = true;
          this.saveFirstExpInfo(this.firstExpData).subscribe(() => {});
        }
      });
  }

  private openCookiesDialog(): void {
    // this.dialog
    //   .open(NcConsentComponent, {
    //     panelClass: 'NcConsentDialog',
    //     maxWidth: '100vw',
    //     disableClose: true,
    //     hasBackdrop: true,
    //   })
    //   .afterClosed()
    //   .subscribe((response) => {
    //     if (!this.firstExpData.home) {
    //       if (
    //         this.dialog.openDialogs.findIndex(
    //           (dialog) =>
    //             dialog._containerInstance._config.panelClass ===
    //             'FirstExperienceHomeDialog'
    //         ) === -1
    //       ) {
    //         this.openHomeDialog();
    //       }
    //     }
    //   });
  }

  private openIotaDialog(): void {
    this.dialog
      .open(IotaNoticeComponent, {
        panelClass: 'IotaNoticeDialog',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .subscribe((response) => {
        if (!this.firstExpData.load) {
          if (
            this.dialog.openDialogs.findIndex(
              (dialog) =>
                dialog._containerInstance._config.panelClass ===
                'FirstExperienceLoadDialog'
            ) === -1
          ) {
            this.openLoadDialog();
          }
        }
      });
  }
}
