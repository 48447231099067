<div class="dropzone-container">
  <div class="dropzone dz-clickable hzScroll hyp-dropzone" id="initialState1" [ngClass]="{ 'invisible': !visible }" #dropzoneDiv>
    <div class="dz-default dz-message" *ngIf="numImages === 0 && state === 0">
      <img class="dropzone-ico" src="{{welcomeIco}}" draggable="false">
      <p [innerHTML]="welcomeMessage"></p>
    </div>
  </div>
</div>
<div class="images">
  <div class="total-container">
    <img src="assets/images/main/model3d/dialogs/img-icon.svg">
    <div class="total-img">
      <div class="current" [innerHTML]="numImages"></div>
      <div class="bar" [innerHTML]="'/'"></div>
      <div class="max" [innerHTML]="maxFiles"></div>
    </div>
  </div>
  <div class="btn" [ngClass]="{'disabled': numImages < 5 || state === allStates.LOADING }"
    [innerHTML]="'add_Hyper_Data3' | i18n" *ngIf="numImages > 0" (click)="uploadFiles()">
  </div>
  <img class="add-ico" src="assets/images/main/model3d/dialogs/add-icon.svg" *ngIf="numImages <= 0 && state !== allStates.LOADING" (click)="open()">
  <div class="loading-container" *ngIf="state === allStates.LOADING">
    <img class="loading" src="assets/images/main/model3d/dialogs/loading-icon.svg">
    <div class="loading-txt" [innerHTML]=" 'add_Hyper_Data4' | i18n"></div>
  </div>
</div>
