import {
  FirstExperienceGalleryStepsEnum,
  FirstExperienceHomeStepsEnum,
  FirstExperienceLoadStepsEnum,
  FirstExperienceNewModelStepsEnum,
  FirstExperienceViewprojectStepsEnum,
} from './first-experience-enum';

export const HomeStepsConfig = [
  {
    key: FirstExperienceHomeStepsEnum.FIRST,
    description: 'first_experience_dialogs_Data5',
  },
  {
    key: FirstExperienceHomeStepsEnum.SECOND,
    description: 'first_experience_dialogs_Data7',
  },
  {
    key: FirstExperienceHomeStepsEnum.THIRD,
    description: 'first_experience_dialogs_Data8',
  },
  {
    key: FirstExperienceHomeStepsEnum.FOURTH,
    description: 'first_experience_dialogs_Data9',
  },
  {
    key: FirstExperienceHomeStepsEnum.FIFTH,
    description: 'first_experience_dialogs_Data10',
  },
  {
    key: FirstExperienceHomeStepsEnum.LAST,
    description: 'first_experience_dialogs_Data11',
  },
];

export const LoadStepsConfig = [
  {
    key: FirstExperienceLoadStepsEnum.FIRST,
    description: 'first_experience_dialogs_Data13',
  },
  {
    key: FirstExperienceLoadStepsEnum.SECOND,
    description: 'first_experience_dialogs_Data14',
  },
  {
    key: FirstExperienceLoadStepsEnum.THIRD,
    description: 'first_experience_dialogs_Data15',
  },
  {
    key: FirstExperienceLoadStepsEnum.FOURTH,
    description: 'first_experience_dialogs_Data16',
  },
  // {
  //   key: FirstExperienceLoadStepsEnum.LAST,
  //   description: 'first_experience_dialogs_Data17',
  // },
];

export const NewModelStepsConfig = [
  {
    key: FirstExperienceNewModelStepsEnum.FIRST,
    description: 'first_experience_dialogs_Data19',
  },
  {
    key: FirstExperienceNewModelStepsEnum.SECOND,
    description: 'first_experience_dialogs_Data20',
  },
  {
    key: FirstExperienceNewModelStepsEnum.THIRD,
    description: 'first_experience_dialogs_Data21',
  },
  {
    key: FirstExperienceNewModelStepsEnum.LAST,
    description: 'first_experience_dialogs_Data22',
  },
];

export const GalleryStepsConfig = [
  {
    key: FirstExperienceGalleryStepsEnum.FIRST,
    description: 'first_experience_dialogs_Data24',
  },
  {
    key: FirstExperienceGalleryStepsEnum.SECOND,
    description: 'first_experience_dialogs_Data25',
  },
  {
    key: FirstExperienceGalleryStepsEnum.THIRD,
    description: 'first_experience_dialogs_Data26',
  },
  {
    key: FirstExperienceGalleryStepsEnum.FOURTH,
    description: 'first_experience_dialogs_Data27',
  },
  {
    key: FirstExperienceGalleryStepsEnum.FIFTH,
    description: 'first_experience_dialogs_Data28',
  },
  {
    key: FirstExperienceGalleryStepsEnum.SIXTH,
    description: 'first_experience_dialogs_Data35',
  },
  {
    key: FirstExperienceGalleryStepsEnum.LAST,
    description: 'first_experience_dialogs_Data36',
  },
];

export const ViewprojectStepsConfig = [
  {
    key: FirstExperienceViewprojectStepsEnum.FIRST,
    description: 'first_experience_dialogs_Data37',
  },
  {
    key: FirstExperienceViewprojectStepsEnum.SECOND,
    description: 'first_experience_dialogs_Data38',
  },
  {
    key: FirstExperienceViewprojectStepsEnum.THIRD,
    description: 'first_experience_dialogs_Data39',
  },
  {
    key: FirstExperienceViewprojectStepsEnum.LAST,
    description: 'first_experience_dialogs_Data40',
  },
];
