<div class="footer-container" (window:resize)="onResize()" #scrollableFooter>

  <div class="mobile-footer" *ngIf="isMobile">
    <a class="footer-action" routerLink="" routerLinkActive="active">
      <img class="icon home" src="/assets/images/main/footer/mobile/home-ico.svg">
      <p class="mobile-text" [innerHTML]="'footer_Data1' | i18n"></p>
    </a>
    <a class="footer-action" routerLink="/new3d" routerLinkActive="active">
      <img class="icon new" src="/assets/images/main/footer/mobile/add-ico.svg">
      <p class="mobile-text" [innerHTML]="'footer_Data2' | i18n"></p>
    </a>
    <a class="footer-action" routerLink="/gallery" routerLinkActive="active">
      <img class="icon gallery" src="/assets/images/main/footer/mobile/gallery-ico.svg">
      <p class="mobile-text" [innerHTML]="'footer_Data3' | i18n"></p>
    </a>
    <a class="footer-action" [routerLink]="profileLink" routerLinkActive="active">
      <div class="icon-container profile">
        <p>{{userNameLetter}}</p>
      </div>
      <p class="mobile-text" [innerHTML]="'footer_Data4' | i18n"></p>
    </a>
  </div>

  <div class="footer" *ngIf="!isMobile">
    <div class="first-line">
      <div class="logo-container" *ngIf="!mireia">
        <img class="ec3d-logo" src="/assets/images/main/footer/ec3d-logo.svg" alt="eyesCloud3D logo">
        <!-- <img class="anidea-logo" src="/assets/images/main/footer/anidea-logo.svg" alt="eyesCloud3D an idea by logo"> -->
        <div [innerHTML]="'footer_Data12'|i18n" class="and-idea-by"></div>
        <img class="ideaby-logo" src="/assets/images/main/footer/idea-by-logo.svg" alt="eyesCloud3D an idea by logo">
      </div>
      <div class="logo-container" *ngIf="mireia">
        <img src="/assets/images/main/header/mireia-logo.webp" alt="Mireia" class="ec3d-logo mireia-logo">
      </div>

      <div class="divider">
        <div class="download-container" *ngIf="!mireia">
          <p class="download-text" [innerHTML]="'footer_Data5' | i18n">Descarga la aplicación:</p>
          <a href="https://apps.apple.com/es/app/eyescloud3d/id1479032117" target="_blank">
            <img src="/assets/images/main/footer/app-store.svg" alt="Descarga iOS app">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.ecapture3d.eyescloud3d" target="_blank">
            <img src="/assets/images/main/footer/google-play.svg" alt="Descarga android app">
          </a>
        </div>

        <div class="social-container" *ngIf="!mireia">
          <p class="social-text" [innerHTML]="'footer_Data6' | i18n"></p>
          <a href="https://www.instagram.com/eyescloud3d/?hl=es" target="_blank">
            <img src="/assets/images/main/footer/ig-ico.svg" alt="Instagram">
          </a>
          <a href="https://www.facebook.com/eyesCloud3d/" target="_blank">
            <img src="/assets/images/main/footer/fb-ico.svg" alt="Facebook">
          </a>
          <a href="https://twitter.com/eyesCloud3D" target="_blank">
            <img src="/assets/images/main/footer/twitter-ico.svg" alt="Twitter">
          </a>
          <a href="https://www.linkedin.com/showcase/eyescloud3d" target="_blank">
            <img src="/assets/images/main/footer/linkedin-ico.svg" alt="Linkedin">
          </a>
          <a href="https://www.tiktok.com/@eyescloud3d" target="_blank">
            <img src="/assets/images/main/footer/tiktok-ico.svg" alt="TikTok">
          </a>
          <a href="https://www.youtube.com/channel/UCmKCyehTYjj5L7oesp4xokw" target="_blank">
            <img src="/assets/images/main/footer/youtube-ico.svg" alt="Youtube">
          </a>
        </div>

        <div class="language-selector">
          <app-language-selector [footer]="true"></app-language-selector>
        </div>
      </div>
    </div>

    <div class="second-line" [ngClass]="{'mireia-second': mireia}">
      <div class="copyright-text" [innerHTML]="'footer_Data7' | i18n : translationModes.INSERT_VALUE: year"></div>
      <div class="privacy-container">
        <a href="https://eyescloud3d.com/condiciones-uso" [innerHTML]="'footer_Data8' | i18n" target="_blank"></a>
        <div class="separator"></div>
        <a href="https://eyescloud3d.com/politica-privacidad" [innerHTML]="'footer_Data9' | i18n" target="_blank"></a>
        <div class="separator"></div>
        <a href="https://eyescloud3d.com/cookies" [innerHTML]="'footer_Data10' | i18n" target="_blank"></a>
        <div class="separator"></div>
        <a href="/hiring" [innerHTML]="'footer_Data11' | i18n" target="_blank"></a>
      </div>
    </div>

  </div>

</div>
