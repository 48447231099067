<img class="close-icon" src="/assets/images/main/iota-cookies/close.svg" (click)="close()">
<div class="home-steps-container" [ngClass]="{'steps-container-second': currentStep !== allSteps.FIRST}">
  <div class="triangle" *ngIf="currentStep !== allSteps.FIRST" [ngClass]="{
      'second-step': currentStep === allSteps.SECOND,
      'third-step': currentStep === allSteps.THIRD,
      'fourth-step': currentStep === allSteps.FOURTH,
      'last-step': currentStep === allSteps.LAST
    }">
  </div>
  <div class="home-steps-description" [innerHTML]="getStepDescription(currentStep) | i18n" [ngClass]="{
      'second-desc': currentStep === allSteps.SECOND,
      'third-desc': currentStep === allSteps.THIRD,
      'fourth-desc': currentStep === allSteps.FOURTH,
      'last-desc': currentStep === allSteps.LAST
    }">
  </div>
  <div class="steps">
    <div>{{ currentStep }}/4</div>
    <div class="step" *ngFor="let step of stepDescriptions" (click)="goToStep(step.key)"
      [ngClass]="{ 'current-step': step.key === currentStep, 'clickable': step.key < currentStep }">
    </div>
  </div>
  <div class="next-step" (click)="nextStep()" [ngClass]="{'last-next-button': currentStep === allSteps.FOURTH}">
    <div
      [innerHTML]="(currentStep === allSteps.FOURTH ? 'first_experience_dialogs_Data18' : 'first_experience_dialogs_Data6') | i18n">
    </div>
    <img *ngIf="currentStep !== allSteps.FOURTH" class="next-arrow" src="/assets/images/main/home/path-down-ico.svg">
  </div>
</div>