import { Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter, HostListener } from "@angular/core";
import { MatLegacyError as MatError } from '@angular/material/legacy-form-field';
import * as Dropzone from 'dropzone';
import { DropzoneFile, DropzoneOptions } from 'dropzone';
import { fromEvent, Observable } from 'rxjs';
import { EventService } from "src/app/core/services/event.service";
import { ModelRemoteService } from "src/app/core/services/remote/model/model-remote.service";
import { Model } from 'src/app/models/model';
import { UserToken } from 'src/app/models/security/user-token';
import { DropzoneFileToUpload } from 'src/app/models/ui/dropzone-file-to-upload';
import { FileLoaderState } from 'src/app/models/ui/file-loader-state';
import { FileUploadResponse } from 'src/app/models/ui/file-upload-response';
import { DropzoneCommon } from 'src/app/new-3d/dropzone-common.service';
import { ModelStorageRemoteService } from 'src/app/services/file-storage/model-storage-remote.service';
import { SecurityTokenStorage } from 'src/app/services/security/security-token-storage';
import { ImgUploadedDialogComponent } from "../dialogs/img-uploaded-dialog/img-uploaded-dialog.component";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-hyperspectral-dropzone',
  templateUrl: './hyperspectral-dropzone.component.html',
  styleUrls: ['./hyperspectral-dropzone.component.scss']
})
export class HyperspectralDropzoneComponent implements OnInit, OnChanges {

  dropzone: Dropzone;
  dropzoneOptions: DropzoneOptions;
  colorClass = 'blue';
  missingFiles: number;
  overFiles: number;
  numImages = 0;
  numBytes = 0;
  isInitDropzone = false;
  state = FileLoaderState.NONE;
  allStates: typeof FileLoaderState = FileLoaderState;
  resizeObservable$: Observable<Event>;

  @Input() url: string;
  @Input() externalFiles: DropzoneFileToUpload[] = [];
  @Input() welcomeMessage: string;
  @Input() welcomeIco: string;
  @Input() width = 500;
  @Input() height = 350;
  @Input() minFiles = 5;
  @Input() maxFiles = 200;
  @Input() acceptedFiles: string;
  @Input() visible = true;
  @Input() model: Model;

  @Output() countFilesExceeded = new EventEmitter<DropzoneFile>();
  @Output() maxFilesNotExceded = new EventEmitter<DropzoneFile>();
  @Output() changeNumImages = new EventEmitter<number>();
  @Output() emptyDropzone = new EventEmitter();
  @Output() needUploadMinFiles = new EventEmitter();
  @Output() initUploadFile = new EventEmitter<any>();
  @Output() loadingDropzoneFiles = new EventEmitter<boolean>();
  @Output() uploadMinFilesSuccessful = new EventEmitter();
  @Output() uploadImageSuccessful = new EventEmitter<DropzoneFile>();
  @Output() maxFilesExceded = new EventEmitter<DropzoneFile>();
  @Output() closeDialog = new EventEmitter<boolean>();

  @ViewChild('dropzoneDiv', { static: true }) dropzoneDiv: ElementRef;
  @ViewChild('dropzoneTemplatePreview', { static: true }) dropzoneTemplatePreview: ElementRef;
  @ViewChild('dropzoneError', { static: true }) dropzoneError: MatError;

  constructor(
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private modelStorageRemoteService: ModelStorageRemoteService,
    private dropzoneCommon: DropzoneCommon,
    private eventService: EventService,
    private modelRemoteService: ModelRemoteService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.eventService.on('closeDialogHyperspectral', repsonse => {
      this.deleteAllFile();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitDropzone && this.minFiles && this.maxFiles && this.url && this.acceptedFiles) {
      this.dropzoneInit();
      this.missingFiles = this.minFiles;
      this.overFiles = this.maxFiles;
      this.isInitDropzone = true;
    }
  }

  removeFile(model, file, fileRepeated = false) {
    this.modelStorageRemoteService.delete(model.id, file.name).subscribe((response) => {
      let error = true;
      if (file.file) {
        file.file.remove();
        // this.dropzone.files.pop();
        this.dropzone.files = this.dropzone.files.filter(f => f !== file);
        error = false;
      }
      if (!fileRepeated) {
        this.numImages--;
        if (this.numImages < this.minFiles && this.numImages > 0) {
          this.numImages = this.numImages - this.minFiles;
        }
        if (this.numImages === this.minFiles * -1) {
          this.state = FileLoaderState.NONE;
          this.emptyDropzone.emit(null);
        }

        if (this.numImages <= this.maxFiles) {
          this.maxFilesNotExceded.emit(file);
        }
        this.changeNumImages.emit(this.numImages);

        this.setLimits();
        this.numBytes -= file.size;

        if (error) {
          this.dropzone.files.pop();
          if (this.dropzone.files.length === 0) {
            this.state = FileLoaderState.NONE;
            this.numImages++;
            this.emptyDropzone.emit(null);
          } else if (this.numImages === -1) {
            this.numImages = 5;
          } else {
            this.numImages++;
          }
        }
      } else {
        this.numImages--;

      }
      this.dropzone.emit('removedfile', file);
    }, () => {
    });
  }

  setColorClass(color = 'blue') {
    this.colorClass = color;
  }

  deleteFile(file) {
    this.removeFile(this.model, file);
  }

  /*
   * Valida que todos los parametros necesarios en el dropzone sean correctos para continuar
   */
  validate(): boolean {
    // Si no necesita mas imegenes y no tiene imagenes de mas pues es valido
    return this.missingFiles <= 0 && this.overFiles <= 0;
  }

  open() {
    this.dropzone.element.click();
  }

  deleteAllFile() {
    $('.dz-preview.dropzone-images').each((index, image) => {
      const name = image.dataset.name;
      this.modelStorageRemoteService.delete(this.model.id.toString(), name).subscribe((response) => {
        image.remove();
      }, () => {
        image.remove();
      });
    });
    this.state = FileLoaderState.NONE;
    this.emptyDropzone.emit();
    this.numImages = 0;
  }

  private dropzoneInit() {
    if (!this.dropzone) {
      const dropzoneObject = Dropzone;
      dropzoneObject.autoDiscover = false;
      this.dropzoneDiv.nativeElement.style.width = this.width;
      this.dropzoneDiv.nativeElement.style.height = this.height;
      this.numImages = 0;

      this.dropzoneOptions = {
        previewTemplate:
          '<div class="dz-preview dz-file-preview">' +
          '<div class="dz-image">' +
          '<img data-dz-thumbnail src="/assets/images/main/new3d/dropzone/vid-bg-icon.svg"  style="height: 100%; width: 100%;"/>' +
          '<div class="dz-loading-overlay">' +
          '<img src="/assets/images/main/model3d/dialogs/loading-icon.svg" class="loading-icon"/>' +
          '</div>' +
          '</div>' +
          '</div>',
        url: this.url,
        acceptedFiles: this.acceptedFiles,
        autoProcessQueue: true,
        headers: { Authorization: this.securityTokenStorage.getObjectValue().token },
        chunking: false,
        timeout: 1200000,
        maxFilesize: 1024,
        maxFiles: 1000
      };

      this.dropzone = new Dropzone(this.dropzoneDiv.nativeElement, this.dropzoneOptions);

      this.dropzone.on('addedfile', (file) => {
        this.initUploadFile.emit(file);
        this.loadingDropzoneFiles.emit(true);
        this.state = FileLoaderState.LOADING;
        this.dropzoneCommon.addDzLoading(file, 'HYPERSPECTRAL');
      });

      this.dropzone.on('success', (file: DropzoneFile, fileUploadResponse: FileUploadResponse) => {
        this.dropzoneCommon.addDzLoadOk(file, fileUploadResponse, 'HYPERSPECTRAL');

        const previewElement = file.previewElement;
        if (previewElement) {
          const loadingOverlay = previewElement.querySelector('.dz-loading-overlay');
          if (loadingOverlay) {
            loadingOverlay.remove();
          }
        }

        if (this.numImages === 0) {
          this.numImages = this.minFiles * -1;
        }
        this.numImages++;
        if (this.numImages === 0) {
          this.numImages = this.minFiles;
          this.uploadMinFilesSuccessful.emit();
        }

        this.changeNumImages.emit(this.numImages);
        this.uploadImageSuccessful.emit(file);

        this.setLimits();
        this.numBytes += file.size;
      });

      this.dropzone.on('removedfile', (file) => {
        if (this.numImages === -4) {
          $('.dz-started .dz-default .dz-message').remove();

          $('.dropzone.dz-started .dz-default .dz-message').css({
            display: 'flex'
          });

        }
      });

      this.dropzone.on('queuecomplete', (file) => {
        this.state = FileLoaderState.ALL_OK;
        this.loadingDropzoneFiles.emit(false);
        if (this.numImages > this.maxFiles) {
          this.maxFilesExceded.emit(file);
        }
      });

      this.dropzone.on('error', (file, message) => {
        this.dropzone.emit('removedfile', file);
      });
    }
  }

  private setLimits() {
    this.missingFiles = this.minFiles - this.numImages;
    this.overFiles = this.numImages - this.maxFiles;
  }

  uploadFiles() {
    if (this.numImages >= this.minFiles) {
      this.modelRemoteService.sendToGenerateHyperespectral(this.model).subscribe({
        next: (success) => {
          this.dialog.open(ImgUploadedDialogComponent, {
            maxWidth: '100vw',
            autoFocus: false,
            data: {
              success: true,
              model: this.model,
            },
            panelClass: 'imgUploadedDialog',
          });
          this.closeDialog.emit(true);
        },
        error: (error) => {
          this.dialog.open(ImgUploadedDialogComponent, {
            maxWidth: '100vw',
            autoFocus: false,
            data: {
              success: false,
              model: this.model,
            },
            panelClass: 'imgUploadedDialog',
          });
          this.closeDialog.emit(true);
        },
      });
    }
  }
}
