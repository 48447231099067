export const RUN_OVER_REPORT_I18N_ENTRIES = {
  ro_report_Data1: {
    es: '<p>INFORME DE ANÁLISIS DE ATROPELLO</p>',
    en: '<p>PEDESTRIAN IMPACT ANALYSIS REPORT</p>',
    pt: '<p>RELATÓRIO DE ANÁLISE DO ATROPELAMENTO</p>',
    fr: '<p>RAPPORT D’ANALYSE DE COLLISION</p>',
    eus: '<p>HARRAPATZE ANALISIARI BURUZKO TXOSTENA</p>',
    cat: '<p>INFORME D\'ANÀLISI D\'ATROPELLAMENT</p>',
  },

  ro_report_Data2: {
    es: '<p>NOMBRE:</p>',
    en: '<p>NAME:</p>',
    pt: '<p>NOME:</p>',
    fr: '<p>NOM :</p>',
    eus: '<p>IZENA:</p>',
    cat: '<p>NOM:</p>',
  },
  ro_report_Data3: {
    es: '<p>FECHA:</p>',
    en: '<p>DATE:</p>',
    pt: '<p>DATA:</p>',
    fr: '<p>DATE :</p>',
    eus: '<p>DATA:</p>',
    cat: '<p>DATA:</p>',
  },
  ro_report_Data4: {
    es: '<p>HORA:</p>',
    en: '<p>TIME:</p>',
    pt: '<p>HORA:</p>',
    fr: '<p>HEURE :</p>',
    eus: '<p>ORDUA:</p>',
    cat: '<p>HORA:</p>',
  },
  ro_report_Data5: {
    es: '<p>CÓDIGO DEL MODELO:</p>',
    en: '<p>MODEL CODE:</p>',
    pt: '<p>CÓDIGO DO MODELO:</p>',
    fr: '<p>CODE DU MODÈLE :</p>',
    eus: '<p>EREDUAREN KODEA:</p>',
    cat: '<p>CODI DEL MODEL:</p>',
  },
  ro_report_Data6: {
    es: '<p>TIPO DE CÁLCULO:</p>',
    en: '<p>CALCULATION TYPE:</p>',
    pt: '<p>TIPO DE CÁLCULO:</p>',
    fr: '<p>TYPE DE CALCUL :</p>',
    eus: '<p>KALKULU MOTA:</p>',
    cat: '<p>TIPUS DE CÀLCULS:</p>',
  },
  ro_report_Data7: {
    es: '<p>Distancia recorrida</p>',
    en: '<p>Distance covered</p>',
    pt: '<p>Distância percorrida</p>',
    fr: '<p>Distance parcourue</p>',
    eus: '<p>Egindako distantzia</p>',
    cat: '<p>Distància recorreguda</p>',
  },
  ro_report_Data8: {
    es: '<p>Tipo de vehículo</p>',
    en: '<p>Vehicle type</p>',
    pt: '<p>Tipo de veículo</p>',
    fr: '<p>Type de véhicule</p>',
    eus: '<p>Ibilgailu mota</p>',
    cat: '<p>Tipus de vehicle</p>',
  },
  ro_report_Data9: {
    es: '<p>Tipo de peatón</p>',
    en: '<p>Pedestrian type</p>',
    pt: '<p>Tipo de peão</p>',
    fr: '<p>Type de piéton</p>',
    eus: '<p>Oinezko mota</p>',
    cat: '<p>Tipus de vianant</p>',
  },
  ro_report_Data10: {
    es: '<p>Velocidad del vehículo</p>',
    en: '<p>Vehicle speed</p>',
    pt: '<p>Velocidade do veículo</p>',
    fr: '<p>Vitesse du véhicule</p>',
    eus: '<p>Ibilgailuaren abiadura</p>',
    cat: '<p>Velocitat del vehicle</p>',
  },
  ro_report_Data11: {
    es: '<p>Coef. Fricción</p>',
    en: '<p>Friction Coeff.</p>',
    pt: '<p>Coef. fricção</p>',
    fr: '<p>Coeff. friction</p>',
    eus: '<p>Marruskadura-koef.</p>',
    cat: '<p>Coef. fricció</p>',
  },
  ro_report_Data12: {
    es: '<p>Modelo 3D en Visor de Malla eyesCloud3D</p>',
    en: '<p>3D Model in eyesCloud3D Mesh Viewer</p>',
    pt: '<p>Modelo 3D em visor de malha eyesCloud3D</p>',
    fr: '<p>Modèle 3D dans la visionneuse de maille eyesCloud3D</p>',
    eus: '<p>3D eredua eyesCloud3D sare-ikustailean</p>',
    cat: '<p>Model 3D en Visor de Malla eyesCloud3D</p>',
  },
  ro_report_Data13: {
    es: '<p>eyesCloud3D trabaja con un protocolo de seguridad IOTA basado en Tangle</p>',
    en: '<p>eyesCloud3D works with an IOTA security protocol based on Tangle</p>',
    pt: '<p>eyesCloud3D trabalha com um protocolo de segurança IOTA baseado em Tangle</p>',
    fr: '<p>eyesCloud3D utilise un protocole de sécurité IOTA basé sur Tangle</p>',
    eus: '<p>eyesCloud3D-k Tangle teknologian oinarritutako IOTA segurtasun protokoloa erabiltzen du.</p>',
    cat: '<p>eyesCloud3D treballa amb un protocol de seguretat IOTA basat en Tangle</p>',
  },
  ro_report_Data14: {
    es: '<p>Modelo matemático: %X%</p>',
    en: '<p>Mathematical Model: %X%</p>',
    pt: '<p>Modelo matemático: %X%</p>',
    fr: '<p>Modèle mathématique : %X%</p>',
    eus: '<p>Eredu matematikoa: %X%</p>',
    cat: '<p>Model matemàtic: %X%</p>',
  },
  ro_report_Data15: {
    es: '<p>Parámetros de Cálculo</p>',
    en: '<p>Calculation Parameters</p>',
    pt: '<p>Parâmetros de Cálculo</p>',
    fr: '<p>Paramètres de calcul</p>',
    eus: '<p>Kalkulu-parametroak</p>',
    cat: '<p>Paràmetres de càlcul</p>',
  },
  ro_report_Data16: {
    es: '<p>Appel</p>',
    en: '<p>Appel</p>',
    pt: '<p>Appel</p>',
    fr: '<p>Appel</p>',
    eus: '<p>Appel</p>',
    cat: '<p>Appel</p>',
  },
  ro_report_Data17: {
    es: '<p>Searle</p>',
    en: '<p>Searle</p>',
    pt: '<p>Searle</p>',
    fr: '<p>Searle</p>',
    eus: '<p>Searle</p>',
    cat: '<p>Searle</p>',
  },
  ro_report_Data18: {
    es: '<p>S</p>',
    en: '<p>S</p>',
    pt: '<p>S</p>',
    fr: '<p>S</p>',
    eus: '<p>S</p>',
    cat: '<p>S</p>',
  },
  ro_report_Data19: {
    es: '<p>H (Tipo de peatón)</p>',
    en: '<p>H (Pedestrian type)</p>',
    pt: '<p>H (Tipo de peão)</p>',
    fr: '<p>H (Type de piéton)</p>',
    eus: '<p>H (Oinezko mota)</p>',
    cat: '<p>H (tipus de vianant)</p>',
  },
  ro_report_Data20: {
    es: '<p>capó frontal</p>',
    en: '<p>front hood</p>',
    pt: '<p>capô frontal</p>',
    fr: '<p>Capot avant</p>',
    eus: '<p>aurrealdeko kapota</p>',
    cat: '<p>capó frontal</p>',
  },
  ro_report_Data21: {
    es: '<p>capó en v</p>',
    en: '<p>v-shaped hood</p>',
    pt: '<p>capô em v</p>',
    fr: '<p>Capot en v</p>',
    eus: '<p>V itxurako kapota</p>',
    cat: '<p>capó en v</p>',
  },
  ro_report_Data22: {
    es: '<p>adulto</p>',
    en: '<p>adult</p>',
    pt: '<p>adulto</p>',
    fr: '<p>adulte</p>',
    eus: '<p>heldua</p>',
    cat: '<p>adult</p>',
  },
  ro_report_Data23: {
    es: '<p>niño</p>',
    en: '<p>child</p>',
    pt: '<p>criança</p>',
    fr: '<p>enfant</p>',
    eus: '<p>haurra</p>',
    cat: '<p>infant</p>',
  },
  ro_report_Data24: {
    es: '<p>Análisis de Atropello en Visor 3D</p>',
    en: '<p>Pedestrian Impact Analysis on 3D Viewer</p>',
    pt: '<p>Análise do atropelamento em visor 3D</p>',
    fr: '<p>Analyse de collision dans la visionneuse 3D</p>',
    eus: '<p>Harrapatze analisia 3D ikustailean</p>',
    cat: '<p>Anàlisi d\'atropellament en visor 3D</p>',
  },
  ro_report_Data25: {
    es: '<p>Análisis de Atropello</p>',
    en: '<p>Pedestrian Impact Analysis</p>',
    pt: '<p>Análise do atropelamento</p>',
    fr: '<p>Analyse de collision</p>',
    eus: '<p>Harrapatze analisia</p>',
    cat: '<p>Anàlisi d\'atropellament</p>',
  },
  ro_report_Data26: {
    es: '<p>Parámetro</p>',
    en: '<p>Parameter</p>',
    pt: '<p>Parâmetro</p>',
    fr: '<p>Paramètre</p>',
    eus: '<p>Parametroa</p>',
    cat: '<p>Paràmetre</p>',
  },
  ro_report_Data27: {
    es: '<p>Velocidad de impacto</p>',
    en: '<p>Impact velocity</p>',
    pt: '<p>Velocidade de impacto</p>',
    fr: '<p>Vitesse d’impact</p>',
    eus: '<p>Inpaktuaren abiadura</p>',
    cat: '<p>Velocitat de l\'impacte</p>',
  },
  ro_report_Data28: {
    es: '<p>Distancia de proyección</p>',
    en: '<p>Projection distance</p>',
    pt: '<p>Distância de projeção</p>',
    fr: '<p>Distance de projection</p>',
    eus: '<p>Proiekzio-distantzia</p>',
    cat: '<p>Distància de projecció</p>',
  },
  ro_report_Data29: {
    es: '<p>Pendiente</p>',
    en: '<p>Gradient</p>',
    pt: '<p>Pendente</p>',
    fr: '<p>Pente</p>',
    eus: '<p>Malda</p>',
    cat: '<p>Pendent</p>',
  },
  ro_report_Data30: {
    es: '<p>Valor</p>',
    en: '<p>Value</p>',
    pt: '<p>Valor</p>',
    fr: '<p>Valeur</p>',
    eus: '<p>Balioa</p>',
    cat: '<p>Valor</p>',
  },
  ro_report_Data31: {
    es: '<p>Distancia recorrida (S): %X%</p>',
    en: '<p>Distance covered (S): %X%</p>',
    pt: '<p>Distância percorrida (S): %X%</p>',
    fr: '<p>Distance parcourue (S) : %X%</p>',
    eus: '<p>Egindako distantzia (S): %X%</p>',
    cat: '<p>Distància recorreguda (S): %X%</p>',
  },
  ro_report_Data32: {
    es: '<p>Tipo de peatón (H): %X%</p>',
    en: '<p>Pedestrian type (H): %X%</p>',
    pt: '<p>Tipo de peão (H): %X%</p>',
    fr: '<p>Type de piéton (H) : %X%</p>',
    eus: '<p>Oinezko mota %X%</p>',
    cat: '<p>Tipus de vianant (H): %X%</p>',
  },
  ro_report_Data33: {
    es: '<p>%X% m</p>',
    en: '<p>%X% m</p>',
    pt: '<p>%X% m</p>',
    fr: '<p>%X% m</p>',
    eus: '<p>%X% m</p>',
    cat: '<p>%X% m</p>',
  },
  ro_report_Data34: {
    es: '<p>%X% Km/h</p>',
    en: '<p>%X% Km/h</p>',
    pt: '<p>%X% Km/h</p>',
    fr: '<p>%X% Km/h</p>',
    eus: '<p>%X% Km/h</p>',
    cat: '<p>%X% Km/h</p>',
  },
 
  ro_report_Data36: {
    es: '<p>%X% m/s²</p>',
    en: '<p>%X% m/s²</p>',
    pt: '<p>%X% m/s²</p>',
    fr: '<p>%X% m/s²</p>',
    eus: '<p>%X% m/s²</p>',
    cat: '<p>%X% m/s²</p>',
  },
  ro_report_Data37: {
    es: '<p>Camión</p>',
    en: '<p>Truck</p>',
    pt: '<p>Camião</p>',
    fr: '<p>Camion</p>',
    eus: '<p>Kamioia</p>',
    cat: '<p>Camió</p>',
  },
  ro_report_Data38: {
    es: '<p>Furgoneta</p>',
    en: '<p>Van</p>',
    pt: '<p>Carrinha</p>',
    fr: '<p>Camionnette</p>',
    eus: '<p>Furgoneta</p>',
    cat: '<p>Furgoneta</p>',
  },
  ro_report_Data39: {
    es: '<p>Turismo</p>',
    en: '<p>Car</p>',
    pt: '<p>Turismo</p>',
    fr: '<p>Voiture</p>',
    eus: '<p>Autoa</p>',
    cat: '<p>Turisme</p>',
  },
  ro_report_Data40: {
    es: '<p>Reconstrucción</p>',
    en: '<p>Reconstruction</p>',
    pt: '<p>Reconstrução</p>',
    fr: '<p>Reconstitution</p>',
    eus: '<p>Berreraikuntza</p>',
    cat: '<p>Reconstrucció</p>',
  },
  ro_report_Data41: {
    es: '<p>Simulación</p>',
    en: '<p>Simulation</p>',
    pt: '<p>Simulação</p>',
    fr: '<p>Simulation</p>',
    eus: '<p>Simulazioa</p>',
    cat: '<p>Simulació</p>',
  },
  ro_report_Data42: {
    es: '<p>Variables</p>',
    en: '<p>Variables</p>',
    pt: '<p>Variáveis</p>',
    fr: '<p>Variables</p>',
    eus: '<p>Aldagaiak</p>',
    cat: '<p>Variables</p>',
  },
  ro_report_Data43: {
    es: '<p>0,084</p>',
    en: '<p>0.084</p>',
    pt: '<p>0,084</p>',
    fr: '<p>0,084</p>',
    eus: '<p>0,084</p>',
    cat: '<p>0,084</p>',
  },
  ro_report_Data44: {
    es: '<p>0,065</p>',
    en: '<p>0.065</p>',
    pt: '<p>0,065</p>',
    fr: '<p>0,065</p>',
    eus: '<p>0,065</p>',
    cat: '<p>0,065</p>',
  },
  ro_report_Data45: {
    es: '<p>0,070</p>',
    en: '<p>0.070</p>',
    pt: '<p>0,070</p>',
    fr: '<p>0,070</p>',
    eus: '<p>0,070</p>',
    cat: '<p>0,070</p>',
  },
  ro_report_Data46: {
    es: '<p>S =   0,084 * V</p>',
    en: '<p>S =   0.084 * V</p>',
    pt: '<p>S =   0,084 * V</p>',
    fr: '<p>S =   0,084 * V</p>',
    eus: '<p>S =   0,084 * V</p>',
    cat: '<p>S =   0,084 * V</p>',
  },
  ro_report_Data47: {
    es: '<p>2</p>',
    en: '<p>2</p>',
    pt: '<p>2</p>',
    fr: '<p>2</p>',
    eus: '<p>2</p>',
    cat: '<p>2</p>',
  },
  ro_report_Data48: {
    es: '<p>S =   0,065 * V</p>',
    en: '<p>S =   0.065 * V</p>',
    pt: '<p>S =   0,065 * V</p>',
    fr: '<p>S =   0,065 * V</p>',
    eus: '<p>S =   0,065 * V</p>',
    cat: '<p>S =   0,065 * V</p>',
  },
 
  ro_report_Data49: {
    es: '<p>S =   0,070 * V</p>',
    en: '<p>S =   0.070 * V</p>',
    pt: '<p>S =   0,070 * V</p>',
    fr: '<p>S =   0,070 * V</p>',
    eus: '<p>S =   0,070 * V</p>',
    cat: '<p>S =   0,070 * V</p>',
  },
  ro_report_Data50: {
    es: '<p>μ Coef. fricción</p>',
    en: '<p>μ Friction coeff.</p>',
    pt: '<p>μ Coef. fricção</p>',
    fr: '<p>μ Coeff. friction</p>',
    eus: '<p>μ Marruskadura-koef.</p>',
    cat: '<p>μ Coef. fricció</p>',
  },
  ro_report_Data51: {
    es: '<p>X</p>',
    en: '<p>X</p>',
    pt: '<p>X</p>',
    fr: '<p>X</p>',
    eus: '<p>X</p>',
    cat: '<p>X</p>',
  },
  ro_report_Data52: {
    es: '<p>g</p>',
    en: '<p>g</p>',
    pt: '<p>g</p>',
    fr: '<p>g</p>',
    eus: '<p>g</p>',
    cat: '<p>g</p>',
  },
  ro_report_Data53: {
    es: '<p>H</p>',
    en: '<p>H</p>',
    pt: '<p>H</p>',
    fr: '<p>H</p>',
    eus: '<p>H</p>',
    cat: '<p>H</p>',
  },
  ro_report_Data54: {
    es: '<p>H =</p>',
    en: '<p>H =</p>',
    pt: '<p>H =</p>',
    fr: '<p>H =</p>',
    eus: '<p>H =</p>',
    cat: '<p>H =</p>',
  },
  ro_report_Data55: {
    es: '<p>Métodos de Cálculo</p>',
    en: '<p>Calculation methods</p>',
    pt: '<p>Métodos de Cálculo</p>',
    fr: '<p>Méthodes de calcul</p>',
    eus: '<p>Kalkulu metodoak</p>',
    cat: '<p>Mètodes de càlcul</p>',
  },
};
