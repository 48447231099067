export const MODEL_NOT_FOUND_I18N_ENTRIES = {
  not_found_Data1: {
    es: '<p>404 ERROR</p>',
    en: '<p>404 ERROR</p>',
    pt: '<p>404 ERROR</p>',
    fr: '<p>404 ERREUR</p>',
    eus: '<p>404 ERROREA</p>',
    cat: '<p>ERROR 404</p>',
  },

  not_found_Data2: {
    es: '<p>El <span class="model-not-found-bold">modelo 3D</span> no existe o ha sido eliminado</p><p>por el/la usuario/a propietario/a del mismo.</p>',
    en: '<p>The <span class="model-not-found-bold">3D model</span> does not exist or has been deleted</p><p>by the user who owns it.</p>',
    pt: '<p>O <span class="model-not-found-bold">modelo 3D</span> não existe ou foi eliminado</p><p>pelo/a utilizador/a proprietário/a do mesmo.</p>',
    fr: '<p>Le <span class="model-not-found-bold">modèle 3D</span> n’existe pas ou a été supprimé</p><p>par l’utilisateur propriétaire de celui-ci.</p>',
    eus: '<p><span class="model-not-found-bold">3D eredua</span> ez dago edo haren jabe den</p><p>erabiltzaileak ezabatu egin du.</p>',
    cat: '<p>El <span class="model-not-found-bold">model 3D</span> no existeix o l’ha eliminat</p><p>l’usuari o usuària a qui pertany.</p>',
  },
  not_found_Data3: {
    es: '<p>VOLVER A LA GALERÍA</p>',
    en: '<p>BACK TO GALLERY</p>',
    pt: '<p>REGRESSAR À GALERIA</p>',
    fr: '<p>RETOUR À LA GALERIE</p>',
    eus: '<p>ITZULI GALERIARA</p>',
    cat: '<p>TORNA A LA GALERIA</p>',
  },
};
