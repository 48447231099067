import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './config/app.routes';
import { CustomPreloadingStrategy } from './core/services/custom-preloading-strategy';
import { I18nService } from './core/services/i18n.service';
import { APP_I18N_ENTRIES } from './config/app-i18n-entries';
import { CommonsModule } from './commons/commons.module';
import { AppConfigLoaderService } from './services/config/app-config-loader.service';
import {
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { ErrorInterceptor } from './services/security/error-interceptor';
import { CoreModule } from './core/core.module';
import { SelectIotaDownloadDialogComponent } from './components/dialogs/select-iota-download-dialog/select-iota-download-dialog.component';
import { ConfirmDeleteModelDialogComponent } from './components/dialogs/confirm-delete-model-dialog/confirm-delete-model-dialog.component';
import { IotaProcessPdfComponent } from './components/iota-pdf/iota-process-pdf/iota-process-pdf.component';
import { IotaImagePdfComponent } from './components/iota-pdf/iota-image-pdf/iota-image-pdf.component';
import { IotaDownloadPdfComponent } from './components/iota-pdf/iota-download-pdf/iota-download-pdf.component';
import { HomeComponent } from './components/home/home.component';
import { AddHyperspectralDialogComponent } from './components/dialogs/add-hyperspectral-dialog/add-hyperspectral-dialog.component';
import { HyperspectralDropzoneComponent } from './components/hyperspectral-dropzone/hyperspectral-dropzone.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IotaBeforeImplementationComponent } from './components/dialogs/form-before-iota-implementation/form-before-iota-implementation';
import { GALLERY_I18N_ENTRIES } from './gallery/config/gallery-i18n-entries';
import { VIEWER_I18N_ENTRIES } from './viewer/config/viewer-i18n-entries';
import { AUTH_I18N_ENTRIES } from './auth/config/auth-i18n-entries';
import { COMMONS_I18N_ENTRIES } from './commons/config/commons-i18n-entries';
import { NEW_3D_I18N_ENTRIES } from './new-3d/config/new-3d-i18n-entries';
import { STANDALONE_CLIENT_I18N_ENTRIES } from './viewer/config/standalone-client-i18n-entries';
import { PAYMENT_DIALOGS_I18N_ENTRIES } from './viewer/config/payment-dialogs-i18n-entries';
import { LANGUAGE_SELECTOR_I18N_ENTRIES } from './config/language-selector-i18n-entries';
import { TWO_STEP_VERIFICATION_I18N_ENTRIES } from './auth/config/two-step-verification-i18n-entries';
import { DELETE_ACCOUNT_DIALOG_I18N_ENTRIES } from './viewer/config/delete-account-dialog-i18n-entries';
import { ModelInfoIOTAService } from './core/services/remote/model/model-info-iota.service';
import { GenerationFailedDialogComponent } from './components/dialogs/generation-failed-dialog/generation-failed-dialog.component';
import { NEW3D_I18N_ENTRIES } from './new-3d/config/new3d-i18n-entries';
import { MAX_EXTENSION_FILES_I18N_ENTRIES } from './new-3d/config/max-extension-files-dialog-i18n-entries';
import { EXTENSION_FILE_TYPE_I18N_ENTRIES } from './new-3d/config/extension-file-type-dialog-i18n-entries copy';
import { IOTA_DOWNLOAD_PDF_I18N_ENTRIES } from './viewer-new/i18n/iota-download-pdf-i18n-entries';
import { IOTA_IMAGES_PDF_I18N_ENTRIES } from './viewer-new/i18n/iota-images-pdf-i18n-entries';
import { TWO_FACTOR_I18N_ENTRIES } from './auth/config/two-factor-i18n-entries';
import { IOTA_PROCESS_PDF_I18N_ENTRIES } from './viewer-new/i18n/iota-process-pdf-i18n-entries';
import { DEFORMATION_ENERGY_REPORT_I18N_ENTRIES } from './viewer/config/deformation-energy-report-i18n-entries';
import { VEGETATION_REPORT_I18N_ENTRIES } from './viewer/config/vegetation-report-i18n-entries';
import { META_CONFIG_I18N_ENTRIES } from './core/services/seo/meta-config-i18n-entries';
import { FirstExperienceHomeDialogComponent } from './components/dialogs/first-experience-dialogs/first-experience-home-dialog/first-experience-home-dialog.component';
import { FirstExperienceLoadDialogComponent } from './components/dialogs/first-experience-dialogs/first-experience-load-dialog/first-experience-load-dialog.component';
import { FirstExperienceNewModelDialogComponent } from './components/dialogs/first-experience-dialogs/first-experience-new-model-dialog/first-experience-new-model-dialog.component';
import { FirstExperienceGalleryDialogComponent } from './components/dialogs/first-experience-dialogs/first-experience-gallery-dialog/first-experience-gallery-dialog.component';
import { FirstExperienceViewprojectDialogComponent } from './components/dialogs/first-experience-dialogs/first-experience-viewproject-dialog/first-experience-viewproject-dialog.component';
import { FIRST_EXPERIENCE_DIALOGS_I18N_ENTRIES } from './config/first-experience-dialogs-i18n-entries';
import { MatDialogModule } from '@angular/material/dialog';
import { GlobalErrorHandler } from './core/handlers/global-error-handler';
import { RUN_OVER_I18N_ENTRIES } from './viewer/config/run-over-i18n-entries';
import { RUN_OVER_REPORT_I18N_ENTRIES } from './viewer-new/i18n/run-over-report-i18n-entries';

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    SelectIotaDownloadDialogComponent,
    ConfirmDeleteModelDialogComponent,
    IotaProcessPdfComponent,
    IotaImagePdfComponent,
    IotaDownloadPdfComponent,
    AddHyperspectralDialogComponent,
    HyperspectralDropzoneComponent,
    IotaBeforeImplementationComponent,
    GenerationFailedDialogComponent,
    FirstExperienceHomeDialogComponent,
    FirstExperienceLoadDialogComponent,
    FirstExperienceNewModelDialogComponent,
    FirstExperienceGalleryDialogComponent,
    FirstExperienceViewprojectDialogComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonsModule,
    CoreModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
      enableTracing: false,
    }),
    NgbModule,
    MatDialogModule,
  ],
  providers: [
    CustomPreloadingStrategy,
    AppConfigLoaderService,
    ModelInfoIOTAService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(APP_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(GALLERY_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(VIEWER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(AUTH_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(COMMONS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(NEW_3D_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(STANDALONE_CLIENT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(PAYMENT_DIALOGS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LANGUAGE_SELECTOR_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(TWO_FACTOR_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(DELETE_ACCOUNT_DIALOG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(NEW3D_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(MAX_EXTENSION_FILES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(EXTENSION_FILE_TYPE_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(IOTA_DOWNLOAD_PDF_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(IOTA_IMAGES_PDF_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(RUN_OVER_REPORT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(RUN_OVER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(IOTA_PROCESS_PDF_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(DEFORMATION_ENERGY_REPORT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(VEGETATION_REPORT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(META_CONFIG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FIRST_EXPERIENCE_DIALOGS_I18N_ENTRIES);
  }
}
