<div class="hyperspectralModal">
  <img class="ico-close" src="assets/images/main/model3d/dialogs/close-icon.svg" (click)="closeDialog()">
  <div class="content-header" [innerHTML]="'add_Hyper_Data1' | i18n"></div>
  <div class="content-container">
    <app-hyperspectral-dropzone
      welcomeMessage="{{'add_Hyper_Data2' | i18n}}"
      welcomeIco="assets/images/main/model3d/dialogs/add-hyper.svg"
      [url]="url"
      [minFiles]="minDropzoneImages"
      [maxFiles]="maxDropzoneImages"
      [acceptedFiles]="acceptedFilesDropzoneImages"
      [model]="model"
      (closeDialog)="onCloseDialog()">
    </app-hyperspectral-dropzone>
  </div>
</div>


