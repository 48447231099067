import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoadStepsConfig } from 'src/app/commons/enum/first-experience-configs';
import { FirstExperienceLoadStepsEnum } from 'src/app/commons/enum/first-experience-enum';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-first-experience-load-dialog',
  templateUrl: './first-experience-load-dialog.component.html',
  styleUrls: ['./first-experience-load-dialog.component.scss'],
})
export class FirstExperienceLoadDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  allSteps: typeof FirstExperienceLoadStepsEnum = FirstExperienceLoadStepsEnum;
  currentStep: FirstExperienceLoadStepsEnum =
    FirstExperienceLoadStepsEnum.FIRST;
  stepDescriptions = LoadStepsConfig;

  outline: number;
  offSet: number;
  leftPosition: number;
  topPosition: number;
  initialDialogWidth: number;
  initialDialogHeight: number;

  buttonLoad: HTMLElement;
  buttonImport: HTMLElement;
  buttonTargets: HTMLElement;
  buttonName: HTMLElement;

  initialX: number;
  initialY: number;
  finalX: number;
  finalY: number;
  halfWidthElement: number;
  halfHeightElement: number;

  twoStepContainer;
  constructor(
    public dialog: MatDialogRef<FirstExperienceLoadDialogComponent>,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.buttonLoad = document.querySelector('.upload-btn');
    this.buttonImport = document.querySelector('.upload-model-btn');
    this.buttonTargets = document.querySelector('.download-targets-btn');
    this.buttonName = document.querySelector('.new-3d-header');
    this.twoStepContainer = document.querySelector('.home-steps-container');
    this.getInitialDialog();
  }

  ngOnDestroy(): void {
    this.removeAllClasses();
  }

  private removeAllClasses() {
    if (this.buttonLoad) {
      this.renderer.removeClass(this.buttonLoad, 'fe-mov-opt');
    }
    if (this.buttonImport) {
      this.renderer.removeClass(this.buttonImport, 'fe-mov-opt');
    }
    if (this.buttonTargets) {
      this.renderer.removeClass(this.buttonTargets, 'fe-mov-opt');
    }
    if (this.buttonName) {
      this.renderer.removeClass(this.buttonName, 'fe-mov-opt');
    }
    if (this.twoStepContainer) {
      this.renderer.removeClass(this.twoStepContainer, 'fe-mov-opt');
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.getInitialDialog();
  }

  close() {
    this.dialog.close();
  }

  getStepDescription(step: FirstExperienceLoadStepsEnum): string {
    const stepObject = this.stepDescriptions.find((obj) => obj.key === step);
    return stepObject ? stepObject.description : '';
  }

  nextStep() {
    if (this.currentStep < FirstExperienceLoadStepsEnum.FOURTH) {
      this.currentStep++;
      this.getInitialDialog();
    } else {
      this.dialog.close(FirstExperienceLoadStepsEnum.LAST);
    }
  }

  goToStep(stepKey: FirstExperienceLoadStepsEnum) {
    if (stepKey < this.currentStep) {
      this.currentStep = stepKey;
      this.getInitialDialog();
    }
  }

  getInitialDialog() {
    const initialDialog = document.querySelector(
      '.FirstExperienceLoadDialog'
    ) as HTMLElement;
    if (initialDialog) {
      setTimeout(() => {
        this.initialDialogWidth = initialDialog.offsetWidth;
        this.initialDialogHeight = initialDialog.offsetHeight;
        this.moveDialog();
      }, 1);
    }
  }

  moveDialog(): void {
    this.removeAllClasses();
    if (this.currentStep === FirstExperienceLoadStepsEnum.FIRST) {
      this.moveInitial();
    } else if (this.currentStep === FirstExperienceLoadStepsEnum.SECOND) {
      this.moveToLoad();
    } else if (this.currentStep === FirstExperienceLoadStepsEnum.THIRD) {
      this.moveToImport();
    } else if (this.currentStep === FirstExperienceLoadStepsEnum.FOURTH) {
      this.moveToTargets();
    } else {
      this.moveToName();
    }
  }

  moveInitial() {
    this.leftPosition = (window.innerWidth - this.initialDialogWidth) / 2;
    this.topPosition = (window.innerHeight - this.initialDialogHeight) / 2;
    this.dialog.updatePosition({
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
    });
  }

  moveToLoad(): void {
    this.buttonLoad = document.querySelector('.upload-btn');
    if (this.buttonLoad) {
      this.renderer.addClass(this.buttonLoad, 'fe-mov-opt');
      this.dialog.addPanelClass('top-offset');
      this.outline = this.responsiveService.firstExperience1194() ? 0 : 4;
      // this.leftPosition =
      //   this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
      // this.topPosition = this.finalY - this.outline;

      const rect = this.buttonLoad.getBoundingClientRect();

      this.topPosition = rect.bottom;

      if (window.innerWidth <= 829) {
        this.leftPosition =
          window.innerWidth / 2 -
          ((this.twoStepContainer / 2) * this.twoStepContainer.offsetHeight) /
            2;
      } else {
        this.leftPosition =
          window.innerWidth / 2 -
          this.buttonLoad.offsetWidth / 2 -
          this.twoStepContainer.offsetWidth / 2;
      }
      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToImport(): void {
    // this.buttonImport = document.querySelector('.upload-model-btn');
    // if (this.buttonLoad) {
    //   this.renderer.removeClass(this.buttonLoad, 'fe-mov-opt');
    // }

    if (this.buttonImport) {
      this.renderer.addClass(this.buttonImport, 'fe-mov-opt');
      this.getElementSize(this.buttonImport);
      if (this.responsiveService.firstExperience600()) {
        this.outline = this.responsiveService.firstExperience830() ? 8 : 16;
        this.offSet = this.responsiveService.firstExperience768() ? 30 : 25;
        if (this.responsiveService.firstExperience830()) {
          this.offSet = 20;
        }
        if (this.responsiveService.firstExperience834()) {
          this.outline = 16;
          this.offSet = 30;
        }
        if (this.responsiveService.firstExperience1024()) {
          this.outline = 8;
        }
        if (this.responsiveService.firstExperience1194()) {
          this.outline = 24;
        }
        if (this.responsiveService.firstExperience1280()) {
          this.outline = 12;
        }
        if (this.responsiveService.firstExperience1920()) {
          this.outline = 24;
        }
        // this.leftPosition =
        //   this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
        // this.topPosition =
        //   this.initialY -
        //   this.initialDialogHeight -
        //   this.offSet -
        //   this.outline -
        //   8;
        this.leftPosition =
          this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
        this.topPosition =
          this.initialY -
          this.initialDialogHeight -
          this.offSet -
          this.outline -
          8;
      } else {
        this.outline = 4;
        this.leftPosition =
          this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
        this.topPosition = this.finalY - this.outline;
      }
      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToTargets(): void {
    // this.buttonTargets = document.querySelector('.download-targets-btn');

    // if (this.buttonImport) {
    //   this.renderer.removeClass(this.buttonImport, 'fe-mov-opt');
    // }

    if (this.buttonTargets) {
      this.renderer.addClass(this.buttonTargets, 'fe-mov-opt');
      this.getElementSize(this.buttonTargets);

      this.outline = this.responsiveService.firstExperience414() ? 8 : 4;
      this.offSet = this.responsiveService.firstExperience600() ? 25 : 20;
      if (this.responsiveService.firstExperience600()) {
        this.outline *= 2;
        this.offSet = this.responsiveService.firstExperience768()
          ? 30
          : this.offSet;
      }
      if (this.responsiveService.firstExperience830()) {
        this.outline = 8;
        this.offSet = 20;
      }
      if (this.responsiveService.firstExperience834()) {
        this.outline = 16;
        this.offSet = 30;
      }
      if (this.responsiveService.firstExperience1024()) {
        this.outline = 8;
      }
      if (this.responsiveService.firstExperience1194()) {
        this.outline = 24;
      }
      if (this.responsiveService.firstExperience1280()) {
        this.outline = 12;
      }
      if (this.responsiveService.firstExperience1920()) {
        this.outline = 24;
      }
      this.leftPosition =
        this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
      this.topPosition =
        this.initialY -
        this.initialDialogHeight -
        this.offSet -
        this.outline -
        8;

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToName(): void {
    // this.buttonTargets = document.querySelector('.download-targets-btn');
    // this.buttonName = document.querySelector('.new-3d-header');
    // if (this.buttonTargets) {
    //   this.renderer.removeClass(this.buttonTargets, 'fe-mov-opt');
    // }

    if (this.buttonName) {
      this.renderer.addClass(this.buttonName, 'fe-mov-opt');
      this.getElementSize(this.buttonName);

      this.outline = this.responsiveService.firstExperience1194() ? 0 : 4;
      this.leftPosition =
        this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
      this.topPosition = this.finalY - this.outline;

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  getElementSize(element: HTMLElement): void {
    this.initialX = element.getBoundingClientRect().left;
    this.initialY = element.getBoundingClientRect().top;
    this.finalX = this.initialX + element.offsetWidth;
    this.finalY = this.initialY + element.offsetHeight;
    this.halfWidthElement = (this.finalX - this.initialX) / 2;
    this.halfHeightElement = (this.finalY - this.initialY) / 2;
  }
}
