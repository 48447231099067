export const ADD_HYPER_DIALOG_I18N_ENTRIES = {
  add_Hyper_Data1: {
    es: '<p>Añadir hiperespectrales</p>',
    en: '<p>Add hyperspectrals</p>',
    pt: '<p>Adicionar hiperespetrais</p>',
    fr: '<p>Ajouter hyperspectrales</p>',
    eus: '<p>Gehitu hiperespektralak</p>',
    cat: '<p>Afegeix hiperespectrals</p>',
  },
  add_Hyper_Data2: {
    es: '<p>Añadir fotos</p>',
    en: '<p>Add photos</p>',
    pt: '<p>Adicionar fotografias</p>',
    fr: '<p>Ajouter photos</p>',
    eus: '<p>Gehitu argazkiak</p>',
    cat: '<p>Afegeix fotos</p>',
  },
  add_Hyper_Data3: {
    es: '<p>Añadir</p>',
    en: '<p>Add</p>',
    pt: '<p>Adicionar</p>',
    fr: '<p>Ajouter</p>',
    eus: '<p>Gehitu</p>',
    cat: '<p>Afegeix</p>',
  },
  add_Hyper_Data4: {
    es: '<p>Cargando...</p>',
    en: '<p>Loading...</p>',
    pt: '<p>A carregar...</p>',
    fr: '<p>Chargement en cours</p>',
    eus: '<p>Kargatzen...</p>',
    cat: '<p>S\'està carregant...</p>',
  },
  add_Hyper_Data5: {
    es: '<p>Tus imágenes se han cargado</p><p>correctamente.</p>',
    en: '<p>Your images have been uploaded</p><p>correctly.</p>',
    pt: '<p>As suas imagens foram carregadas</p><p>corretamente</p>',
    fr: '<p>Vos images ont été téléchargées</p><p>correctement</p>',
    eus: '<p>Zure irudiak behar bezala</p><p>kargatu dira.</p>',
    cat: '<p>Les imatges s’han carregat</p><p>correctament.</p>',
  },
  add_Hyper_Data6: {
    es: '<p>No se ha podido cargar las imágenes.</p><p>Por favor, inténtalo de nuevo más tarde.</p>',
    en: '<p>Your images could not be uploaded.</p><p>Please try again later.</p>',
    pt: '<p>Não foi possível carregar as imagens.</p><p>Tente novamente mais tarde.</p>',
    fr: '<p>Les images n\'ont pas pu être téléchargées.</p><p>Veuillez réessayer plus tard.</p>',
    eus: '<p>Ezin izan dira irudiak kargatu.</p><p>Mesedez, saiatu berriro geroago.</p>',
    cat: '<p>No s’ha pogut carregar les imatges.</p><p>Torna a provar-ho més tard.</p>',
  },
  add_Hyper_Data7: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },
  add_Hyper_Data8: {
    es: '<p>Reintentar</p>',
    en: '<p>Try again</p>',
    pt: '<p>Tentar novamente</p>',
    fr: '<p>Réessayer</p>',
    eus: '<p>Berriro saiatu</p>',
    cat: '<p>Reintenta</p>',
  },
};
