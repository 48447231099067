export const NEW3D_I18N_ENTRIES = {

  new3d_Data1: {
    es: '<p>Nuevo Modelo 3D</p>',
    en: '<p>New 3D Model</p>',
    pt: '<p>Novo modelo 3D</p>',
    fr: '<p>Nouveau modèle 3D</p>',
    eus: '<p>3D eredu berria</p>',
    cat: '<p>Nou model 3D</p>',
  },

  new3d_Data2: {
    es: '<p>Nombre del modelo 3D</p>',
    en: '<p>3D model name</p>',
    pt: '<p>Nome do modelo 3D</p>',
    fr: '<p>Nom du modèle 3D</p>',
    eus: '<p>3D ereduaren izena</p>',
    cat: '<p>Nom del model 3D</p>',
  },

  new3d_Data3: {
    es: '<p>Cargar</p>',
    en: '<p>Upload</p>',
    pt: '<p>Carregar</p>',
    fr: '<p>Télécharger</p>',
    eus: '<p>Kargatu</p>',
    cat: '<p>Carrega</p>',
  },

  new3d_Data4: {
    es: '<p>(Fotografías o vídeos)</p>',
    en: '<p>(Photos or videos)</p>',
    pt: '<p>(Fotografias ou vídeos)</p>',
    fr: '<p>(Photos ou vidéos)</p>',
    eus: '<p>( Argazkiak eta/edo bideoak)</p>',
    cat: '<p>(Fotografies o vídeos)</p>',
  },

  new3d_Data5: {
    es: '<p>Capturar</p>',
    en: '<p>Capture</p>',
    pt: '<p>Captar</p>',
    fr: '<p>Capturer</p>',
    eus: '<p>Kapturatu</p>',
    cat: '<p>Captura</p>',
  },

  new3d_Data6: {
    es: '<p>Subir modelo 3D</p>',
    en: '<p>Upload 3d model</p>',
    pt: '<p>Carregar modelo 3D</p>',
    fr: '<p>Télécharger modèle 3D</p>',
    eus: '<p>KARGATU 3D EREDUA</p>',
    cat: '<p>Carrega model 3D</p>',
  },

  new3d_Data7: {
    es: '<p>Sube tus modelos 3D para integrarlos</p><p>en eyesCloud3D</p>',
    en: '<p>Upload your 3D models to integrate them</p><p>into eyesCloud3D</p>',
    pt: '<p>Carregue os seus modelos 3D para integrá-los</p><p>em eyesCloud3D</p>',
    fr: '<p>Téléchargez vos modèles 3D pour les intégrer</p><p>dans eyesCloud3D</p>',
    eus: '<p>Kargatu zure 3D ereduak,</p><p>eyesCloud3D-n integratzeko</p>',
    cat: '<p>Carrega els teus models 3D per integrar-los</p><p>a eyesCloud3D</p>',
  },

  new3d_Data8: {
    es: '<p>Descargar targets</p>',
    en: '<p>Download targets</p>',
    pt: '<p>Transferir targets</p>',
    fr: '<p>Télécharger targets</p>',
    eus: '<p>Deskargatu Target-ak</p>',
    cat: '<p>Baixa targets</p>',
  },

  new3d_Data9: {
    es: '<p>(Incluye instrucciones de uso)</p>',
    en: '<p>(Includes instructions)</p>',
    pt: '<p>(Inclui instruções de utilização)</p>',
    fr: '<p>(Comprend le mode d’emploi)</p>',
    eus: '<p>(Jarraibideak barnean daude)</p>',
    cat: '<p>(Inclou les instruccions d’ús)</p>',
  },

  new3d_Data10: {
    es: '<p class="i18n-bold">Tu tarifa no te permite generar modelos 4Pro3Dmodel</p><p>Actualiza tu tarifa</p>',
    en: '<p class="i18n-bold">Your plan does not allow you to generate 4Pro3Dmodels</p><p>Update your plan</p>',
    pt: '<p class="i18n-bold">A sua tarifa não lhe permite criar modelos 4Pro3Dmodel</p><p>Atualize a sua tarifa</p>',
    fr: '<p class="i18n-bold">Votre abonnement ne vous permet pas de générer des modèles 4Pro3Dmodel. Veuillez mettre à niveau votre forfait</p>',
    eus: '<p class="i18n-bold">Zure tarifak ez du onartzen 4Pro3Dmodel ereduak sortzea</p><p>Hobetu zure tarifa</p>',
    cat: '<p class="i18n-bold">La teva tarifa no et permet generar models 4Pro3Dmodel</p><p>Actualitza la tarifa</p>',
  },

  new3d_Data11: {
    es: '<p>ACTUALIZAR TARIFA</p>',
    en: '<p>UPDATE PLAN</p>',
    pt: '<p>ATUALIZAR TARIFA</p>',
    fr: '<p>METTRE À NIVEAU LE FORFAIT</p>',
    eus: '<p>BERRITU TARIFA</p>',
    cat: '<p>ACTUALITZA LA TARIFA</p>',
  },

  new3d_Data12: {
    es: '<p class="i18n-bold">Generar un modelo 3Dmodel</p><p>La generación de 3Dmodel es gratuita</p><p>Esta opción<span class="i18n-underline"> no incluye descarga.</span></p><p>(entre 5 y 50 fotografías o hasta 1 min de vídeo)</p>',
    en: '<p class="i18n-bold">Generate a 3D model</p><p>You can generate a 3Dmodel free of charge</p><p>This option<span class="i18n-underline"> does not include downloads.</span></p><p>(from 5 to 50 photos or up to 1 min of video)</p>',
    pt: '<p class="i18n-bold">Criar um modelo 3Dmodel</p><p>A criação de 3Dmodel é gratuita</p><p>Esta opção<span class="i18n-underline"> não inclui a transferência.</span></p><p>(entre 5 e 50 fotografias ou até 1 min de vídeo)</p>',
    fr: '< class="i18n-bold">Générer un modèle 3Dmodel</p><p>La génération d\'un modèle 3Dmodel est gratuite</p><p>Cette option<span class="i18n-underline"> n\'inclut pas le téléchargement.</span></p><p>(de 5 à 50 photos ou vidéo de 1 min maximum)</p>',
    eus: '<p class="i18n-bold">Sortu 3Dmodel eredua</p><p>3Dmodel eredua sortzea doakoa da</p><p>Aukera honekin ezin dira deskargak egin.</p><p>(5 eta 50 argazki bitarte edo gehienez 1 min-ko bideoa)</p>',
    cat: '<p class="i18n-bold">Genera un model 3Dmodel</p><p>La generació de 3Dmodel és gratuïta</p><p>Aquesta operació<span class="i18n-underline">no inclou la baixada.</span></p><p>(entre 5 i 50 fotografies o fins a 1 min de vídeo)</p>',
  },

  new3d_Data13: {
    es: '<p>GENERAR MODELO 3Dmodel</p>',
    en: '<p>GENERATE 3Dmodel</p>',
    pt: '<p>CRIAR MODELO 3Dmodel</p>',
    fr: '<p>GÉNÉRER MODÈLE 3Dmodel</p>',
    eus: '<p>SORTU 3Dmodel EREDUA</p>',
    cat: '<p>GENERA MODEL 3Dmodel</p>',
  },

  new3d_Data14: {
    es: '<p>generar un modelo 4Pro3Dmodel</p>',
    en: '<p>generate a 4Pro3Dmodel</p>',
    pt: '<p>criar um modelo 4Pro3Dmodel</p>',
    fr: '<p>générer un modèle 4Pro3Dmodel</p>',
    eus: '<p>Sortu 4PRO3DMODEL eredua</p>',
    cat: '<p>genera un model 4Pro3Dmodel</p>',
  },

  new3d_Data15: {
    es: '<p>Has superado el número máximo de fotos permitidas para</p><p>generar un modelo 3Dmodel. Elimina archivos o <span class="i18n-bold i18n-underline">genera</span></p><p class="i18n-bold i18n-underline">un modelo 4pro3Dmodel.</p>',
    en: '<p>You have exceeded the maximum number of photos allowed</p><p>to generate a 3D model. Please delete files or generate</p><p class="i18n-bold i18n-underline">a 4pro3Dmodel.</p>',
    pt: '<p>Ultrapassou o número máximo de fotografias permitidas para</p><p>a criação de um modelo 3Dmodel. Elimine ficheiros ou crie</p><p class="i18n-bold i18n-underline">um modelo 4pro3Dmodel.</p>',
    fr: '<p>Vous avez dépassé le nombre maximal de photos autorisées pour générer un modèle</p><p>3Dmodel.Supprimez des fichiers ou générez</p><p class="i18n-bold i18n-underline">un modèle 4pro3Dmodel.</p>',
    eus: '<p>3Dmodel eredua sortzeko onartzen den argazki kopuruaren</p><p>muga gainditu duzu.Ezabatu fitxategiak edo</p><p>sortu</p><p class="i18n-bold i18n-underline">4pro3Dmodel eredu bat.</p>',
    cat: '<p>Has assolit la quantitat màxima de fotos permesa per</p><p>generar el model 3Dmodel. Elimina fitxers o genera</p><p class="i18n-bold i18n-underline">un model 4pro3Dmodel.</p>',
  },

  new3d_Data16: {
    es: '<p>generar un modelo 4Pro3Dmodel</p>',
    en: '<p>generate a 4Pro3Dmodel</p>',
    pt: '<p>criar um modelo 4Pro3Dmodel</p>',
    fr: '<p>générer un modèle 4Pro3Dmodel</p>',
    eus: '<p>Sortu 4PRO3DMODEL eredua</p>',
    cat: '<p>genera un model 4Pro3Dmodel</p>',
  },

  new3d_Data17: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  new3d_Data18: {
    es: '<p>Fotografía BLK</p>',
    en: '<p>BLK photo</p>',
    pt: '<p>Fotografia BLK</p>',
    fr: '<p>Photo BLK</p>',
    eus: '<p>BLK argazkia</p>',
    cat: '<p>Fotografia en BLK</p>',
  },

  new3d_Data19: {
    es: '<p>Sube o arrastra el documento de <span class="i18n-bold">datos de calibración</span> de Fotografía BLK</p>',
    en: '<p>Upload or drag and drop the BLK photo <span class="i18n-bold">calibration data</span> document</p>',
    pt: '<p>Carregue ou arraste o documento de <span class="i18n-bold">dados de calibração</span> de Fotografia BLK</p>',
    fr: '<p>Téléchargez ou glissez le document de <span class="i18n-bold">données de calibration</span> de la photo BLK</p>',
    eus: '<p>Kargatu edo arrastatu BLK argazkiaren <span class="i18n-bold">kalibrazio datuen</span> dokumentua</p>',
    cat: '<p>Carrega o arrossega el document de <span class="i18n-bold">dades de calibratge</span> de Fotografia BLK</p>',
  },

  new3d_Data20: {
    es: '<p>SUBIR DATOS DE CALIBRACIÓN</p>',
    en: '<p>UPLOAD CALIBRATION DATA</p>',
    pt: '<p>CARREGAR DADOS DE CALIBRAÇÃO</p>',
    fr: '<p>TÉLÉCHARGER DONNÉES DE CALIBRATION</p>',
    eus: '<p>Kargatu kalibrazio datuak</p>',
    cat: '<p>CARREGA DADES DE CALIBRATGE</p>',
  },

  new3d_Data21: {
    es: '<p>El archivo de datos de calibración subido no es un archivo correcto.</p>',
    en: '<p>The uploaded calibration data file is incorrect.</p>',
    pt: '<p>O formato do ficheiro de dados de calibração carregado é incorreto.</p>',
    fr: '<p>Le fichier de données de calibration téléchargé n’est pas un fichier valide.</p>',
    eus: '<p>Kargatu duzun kalibrazio datuen fitxategia ez da zuzena.</p>',
    cat: '<p>El fitxer de dades de calibratge carregat no és un fitxer correcte.</p>',
  },

  new3d_Data22: {
    es: '<p>Revisa y vuelve a subir los archivos de <span class="i18n-bold">Datos de Calibración</span></p><p>con el formato correcto obtenido del dispositivo.</p>',
    en: '<p>Check and upload the <span class="i18n-bold">Calibration Data</span> files again</p><p>with the correct format obtained from the device.</p>',
    pt: '<p>Verifique e volte a carregar os ficheiros de <span class="i18n-bold">Dados de calibração</span></p><p>com o formato correto obtido do dispositivo.</p>',
    fr: '<p>Vérifiez et téléchargez à nouveau les fichiers de <span class="i18n-bold">données de calibration</span> avec le format correct obtenu du dispositif.</p>',
    eus: '<p>Zuzendu eta berriz ere kargatu Kalibrazio Datuei buruzko fitxategiak,</p><p>gailutik ateratako formatu zuzenarekin.</p>',
    cat: '<p>Revisa i torna a carregar el fitxer de <span class="i18n-bold">dades de calibratge</span></p><p>amb el format correcte obtingut del dispositiu.</p>',
  },

  new3d_Data23: {
    es: '<p>Volver a subir datos de calibración</p>',
    en: '<p>Re-upload calibration data</p>',
    pt: '<p>Carregar novamente dados de calibração</p>',
    fr: '<p>Téléchargez à nouveau les données de calibration</p>',
    eus: '<p>Berriro kargatu kalibrazio datuak</p>',
    cat: '<p>Torna a carregar les dades de calibratge</p>',
  },

  new3d_Data24: {
    es: '<p>Añadir datos</p>',
    en: '<p>Add data</p>',
    pt: '<p>Adicionar dados</p>',
    fr: '<p>Ajouter données</p>',
    eus: '<p>Gehitu datuak</p>',
    cat: '<p>Afegeix dades</p>',
  },

  new3d_Data25: {
    es: '<p>Añadir GPS</p>',
    en: '<p>Add GPS</p>',
    pt: '<p>Adicionar GPS</p>',
    fr: '<p>Ajouter GPS</p>',
    eus: '<p>Gehitu GPS-a</p>',
    cat: '<p>Afegeix GPS</p>',
  },

  new3d_Data26: {
    es: '<p>Sube o arrastra el documento de <span class="i18n-bold">datos GPS</span> de Fotografía BLK</p>',
    en: '<p>Upload or drag and drop the BLK photo <span class="i18n-bold">GPS data</span> document</p>',
    pt: '<p>Carregue ou arraste o documento de <span class="i18n-bold">dados GPS</span> de Fotografia BLK</p>',
    fr: '<p>Téléchargez ou glissez le document de <span class="i18n-bold">données GPS</span> de la photo BLK</p>',
    eus: '<p>Kargatu edo arrastatu BLK argazkiaren <span class="i18n-bold">GPS datuen</span> dokumentua</p>',
    cat: '<p>Carrega o arrossega el document de <span class="i18n-bold">dades GPS</span> de Fotografia BLK</p>',
  },

  new3d_Data27: {
    es: '<p>SUBIR GPS</p>',
    en: '<p>UPLOAD GPS</p>',
    pt: '<p>CARREGAR GPS</p>',
    fr: '<p>TÉLÉCHARGER GPS</p>',
    eus: '<p>KARGATU GPS-A</p>',
    cat: '<p>CARREGA GPS</p>',
  },

  new3d_Data28: {
    es: '<p>El archivo de GPS subido no es un archivo correcto.</p>',
    en: '<p>The uploaded GPS file is incorrect.</p>',
    pt: '<p>O formato do ficheiro GPS carregado é incorreto.</p>',
    fr: '<p>Le fichier GPS téléchargé n\'est pas un fichier valide.</p>',
    eus: '<p>Kargatu duzun GPS fitxategia ez da zuzena.</p>',
    cat: '<p>El fitxer de GPS carregat no és un fitxer correcte.</p>',
  },

  new3d_Data29: {
    es: '<p>Revisa y vuelve a subir los archivos de <span class="i18n-bold">GPS</span> con el formato correcto</p><p>obtenido del dispositivo.</p>',
    en: '<p>Check and upload the <span class="i18n-bold">GPS</span> file in the correct format</p><p>obtained from the device.</p>',
    pt: '<p>Verifique e carregue novamente o ficheiro de <span class="i18n-bold">GPS</span> com o formato correto</p><p>obtido a partir do dispositivo.</p>',
    fr: '<p>Vérifiez et téléchargez à nouveau le fichier <span class="i18n-bold">GPS</span> avec le format correct</p><p>obtenu du dispositif.</p>',
    eus: '<p>Zuzendu eta berriz ere kargatu <span class="i18n-bold">GPS</span> fitxategiagailutik ateratako</p><p>formatu zuzenarekin.</p>',
    cat: '<p>Revisa i torna a carregar el fitxer de <span class="i18n-bold">GPS</span> amb el format correcte</p><p>obtingut del dispositiu.</p>',
  },

  new3d_Data30: {
    es: '<p>Volver a subir GPS</p>',
    en: '<p>Re-upload GPS</p>',
    pt: '<p>Carregar novamente GPS</p>',
    fr: '<p>Téléchargez à nouveau le GPS</p>',
    eus: '<p>Berriz kargatu GPS-a</p>',
    cat: '<p>Torna a carregar GPS</p>',
  },

  new3d_Data31: {
    en: '<p>We have confirmed that you have uploaded <span class="i18n-bold">more than 200 photographs</p><p>or 4 minutes of video</span>.</p><p>You must delete the photographs or reduce the video duration to be</p><p>under the limits.</p>',
    es: '<p>Hemos comprobado que has subido <span class="i18n-bold">más de 200 fotografías</span></p><p>o <span class="i18n-bold">4 minutos de vídeo.</span></p><p>Debes eliminar aquellas fotografías o duración para estar</p><p>por debajo de los límites.</p>',
    pt: '<p>Comprovámos que carregou <span class="i18n-bold">mais de 200 fotografias</span></p><p>ou <span class="i18n-bold">4 minutos de vídeo.</span></p><p>Deve eliminar fotografias ou duração de vídeo para estar</p><p>abaixo dos limites.</p>',
    fr: '<p>Nous avons détecté que vous avez téléchargé <span class="i18n-bold">plus de 200</span> photos</p><p>ou <span class="i18n-bold">4 minutes de vidéo.</span></p><p>Vous devez supprimer des photos ou réduire la durée des vidéos</p><p>pour rester en dessous des limites.</p>',
    eus: '<p>Ikusi dugu <span class="i18n-bold">200 argazki baino gehiago</span> edo</p><p><span class="i18n-bold">4 minutu baino gehiagoko bideoa</span> kargatu duzula.</p><p>Argazki batzuk ezabatu edo iraupena murriztu</p><p>onartutako mugen barruan egoteko.</p>',
    cat: '<p>Hem comprovat que has pujat <span class="i18n-bold">més de 200 fotografies</span></p><p>o <span class="i18n-bold">4 minuts de vídeo</span>.</p><p>Has d\'eliminarl \'excés de fotos o durada per estar</p><p>per sota dels límits.</p>',
  },

  new3d_Data32: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  new3d_Data33: {
    es: '<p>Has superado el número máximo de fotos permitidas para la</p><p>generación de modelo 3D.<span class="i18n-bold"> Elimina hasta tener un</span></p><p class="i18n-bold">total máximo de 200 fotos.</p>',
    en: '<p>You have exceeded the maximum number of photos allowed</p><p>for 3D generation.<span class="i18n-bold"> Delete until you have a</span></p><p class="i18n-bold">maximum of 200 photos.</p>',
    pt: '<p>Ultrapassou o número máximo de fotografias permitidas para a </p><p>criação do modelo 3D.<span class="i18n-bold"> Elimine até ter um </span></p><p class="i18n-bold">total máximo de 200 fotografias.</p>',
    fr: '<p>Vous avez dépassé le nombre maximum de photos autorisées pour la</p><p>génération de modèle 3D.<span class="i18n-bold"> Supprimez-en jusqu’à obtenir un </span></p><p class="i18n-bold">total maximum de 200 photos.</p>',
    eus: '<p>3D eredua sortzeko onartzen den argazki kopuruaren muga</p><p>gailendu duzu.<span class="i18n-bold"> Ezabatu irudiak</span></p><p class="i18n-bold">gehienez 200 argazki izan arte.</p>',
    cat: '<p>Has superat el nombre màxim de fotos permeses per a la</p><p>generació de model 3D.<span class="i18n-bold"> Elimina’n fins a tenir un</span></p><p class="i18n-bold">total màxim de 200 fotos.</p>',
  },

  new3d_Data34: {
    es: '<p>Foto</p>',
    en: '<p>Photo</p>',
    pt: '<p>Fotografia</p>',
    fr: '<p>Photo</p>',
    eus: '<p>Argazkia</p>',
    cat: '<p>Foto</p>',
  },

  new3d_Data35: {
    es: '<p>Vídeo</p>',
    en: '<p>Video</p>',
    pt: '<p>Vídeo</p>',
    fr: '<p>Vidéo</p>',
    eus: '<p>Bideoa</p>',
    cat: '<p>Vídeo</p>',
  },

  new3d_Data36: {
    es: '<p>Este formato de vídeo no puede reproducirse en el navegador</p>',
    en: '<p>This video format cannot be played in your browser</p>',
    pt: '<p>Este formato de vídeo não pode ser reproduzido no navegador</p>',
    fr: '<p>Ce format de vidéo ne peut pas être reproduit dans le navigateur</p>',
    eus: '<p>Bideo formatu hau ezin da nabigatzailean erreproduzitu</p>',
    cat: '<p>Aquest format de vídeo no es pot reproduir al navegador</p>',
  },

  new3d_Data37: {
    es: '<p>El vídeo utilizado para la generación del modelo 3D no es compatible con su navegador.</p>',
    en: '<p>The video used to generate the 3D model is not compatible with your browser.</p>',
    pt: '<p>O vídeo utilizado para a criação do modelo 3D não é compatível com o seu navegador.</p>',
    fr: '<p>La vidéo utilisée pour la génération du modèle 3D n\'est pas compatible avec votre navigateur.</p>',
    eus: '<p>3D eredua sortzeko erabili duzun bideoa ez da zure nabigatzailearekin bateragarria.</p>',
    cat: '<p>El vídeo emprat per generar el model 3D no és compatible amb el navegador.</p>',
  },

  new3d_Data38: {
    es: '<p>Eliminar foto</p>',
    en: '<p>Delete photo</p>',
    pt: '<p>Eliminar fotografia</p>',
    fr: '<p>Supprimer photo</p>',
    eus: '<p>Ezabatu argazkia</p>',
    cat: '<p>Elimina foto</p>',
  },

  new3d_Data39: {
    es: '<p>Eliminar vídeo</p>',
    en: '<p>Delete video</p>',
    pt: '<p>Eliminar vídeo</p>',
    fr: '<p>Supprimer vidéo</p>',
    eus: '<p>Ezabatu bideoa</p>',
    cat: '<p>Elimina vídeo</p>',
  },

  new3d_Data40: {
    es: '<p>¿Quieres eliminar la selección de archivos?</p><p>Al eliminar esta selección de archivos también se</p><p>eliminarán sus datos.</p><p class="i18n-bold">Esta acción NO se puede deshacer.</p>',
    en: '<p>Delete the selected files?</p><p>If you delete these files, the corresponding</p><p>data will also be deleted.</p><p class="i18n-bold">This action CANNOT be undone.</p>',
    pt: '<p>Pretende eliminar a seleção de ficheiros?</p><p>Ao eliminar esta seleção de ficheiros, eliminar- se - ão também</p><p>os seus dados.</p><p class="i18n-bold">Esta ação NÃO é reversível.</p>',
    fr: '<p>Souhaitez-vous supprimer la sélection de fichiers ?</p><p>En supprimant cette sélection de fichiers, vous supprimerez également toutes les données qu’elle contient.</p><p class="i18n-bold">Cette action NE PEUT PAS être annulée.</p>',
    eus: '<p>Hautatu dituzun fitxategiak ezabatu nahi dituzu?</p><p>Hautatu dituzun fitxategiak ezabatzean, horien inguruko datu</p><p>guztiak ere ezabatuko dira.</p><p class="i18n-bold">Ekintza hau EZIN DA desegin.</p>',
    cat: '<p>Vols eliminar la selecció de fitxers?</p><p>Si elimines aquesta selecció de fitxers,</p><p>també s’eliminaran les seves dades.</p><p class="i18n-bold">Aquesta acció NO es pot desfer.</p>',
  },

  new3d_Data41: {
    es: '<p>Nombre:</p>',
    en: '<p>Name:</p>',
    pt: '<p>Nome:</p>',
    fr: '<p>Nom:</p>',
    eus: '<p>Izena:</p>',
    cat: '<p>Nom:</p>',
  },

  new3d_Data42: {
    es: '<p>Créditos:</p>',
    en: '<p>Credits:</p>',
    pt: '<p>Créditos:</p>',
    fr: '<p>Crédits :</p>',
    eus: '<p>Kredituak:</p>',
    cat: '<p>Crèdits:</p>',
  },

  new3d_Data43: {
    es: '<p>Has superado el número máximo de minutos permitidos para</p><p>la generación del modelo 3D.<span class="i18n-bold"> Elimina hasta tener</span></p><p class="i18n-bold">un total máximo de 4 minutos de duración.</p>',
    en: '<p>You have exceeded the maximum number of minutes allowed to</p><p>generate a 3D model.<span class="i18n-bold"> Delete videos until you have</span></p><p class="i18n-bold">a maximum of 4 minutes in total.</p>',
    pt: '<p>Ultrapassou o número máximo de minutos permitidos para</p><p>a criação do modelo 3D.<span class="i18n-bold"> Elimine até ter</span></p><p class="i18n-bold">um total máximo de 4 minutos de duração.</p>',
    fr: '<p>Vous avez dépassé le nombre maximum de minutes autorisées pour la</p><p>génération du modèle 3D.<span class="i18n-bold"> Supprimez-en jusqu\'à obtenir</span></p><p class="i18n-bold">un total maximum de 4 minutes.</p>',
    eus: '<p>3D eredua sortzeko onartzen den minutu kopuruaren</p><p>muga gailendu duzu. <span class="i18n-bold">Ezabatu edukia,</span></p><p class="i18n-bold">gehienez 4 minutuko iraupena izan arte.</p>',
    cat: '<p>Has assolit la quantitat màxima de minuts permesa</p><p>per generar el model 3D.<span class="i18n-bold"> Elimina\'n fins a tenir</span></p><p class="i18n-bold">un màxim total de 4 minuts de durada.</p>',
  },

  new3d_Data44: {
    es: '<p>Importar Modelo 3D</p>',
    en: '<p>Import 3D Model</p>',
    pt: '<p>Importar modelo 3D</p>',
    fr: '<p>Importer un modèle 3D</p>',
    eus: '<p>Inportatu 3D eredua</p>',
    cat: '<p>Importa un model 3D</p>',
  },

  photoVideo: {
    es: '<p>Fotos / Vídeo</p>',
    en: '<p>Photos / Videos</p>',
    pt: '<p>Fotografias/Vídeos</p>',
    fr: '<p>Photos / Vidéos</p>',
    eus: '<p>Argazkiak/ Bideoak</p>',
    cat: '<p>Fotos / Vídeos</p>',
  },

  onlyForMovilOrTablet: {
    es: '<p>(Solo disponible en móvil o tablet)</p>',
    en: '<p>(Only available on smartphones or tablets)</p>',
    pt: '<p>(Apenas disponível em telemóvel ou tablet)</p>',
    fr: '<p>(Uniquement disponible sur mobile ou tablette)</p>',
    eus: '<p>(Soilik telefonoan edo tabletan erabilgarria)</p>',
    cat: '<p>(Només disponible al mòbil o tauleta)</p>',
  },

  add: {
    es: '<p>Añadir</p>',
    en: '<p>Add</p>',
    pt: '<p>Adicionar</p>',
    fr: '<p>Ajouter</p>',
    eus: '<p>GEHITU</p>',
    cat: '<p>Afegeix</p>',
  },

  morePhotos: {
    es: '<p>más fotos</p>',
    en: '<p>more photos</p>',
    pt: '<p>mais fotografias</p>',
    fr: '<p>plus de photos</p>',
    eus: '<p>argazki gehiago</p>',
    cat: '<p>més fotos</p>',
  },

  photodropzoneMessage: {
    es: '<p>Añadir fotos</p>',
    en: '<p>Add photos</p>',
    pt: '<p>Adicionar fotografias</p>',
    fr: '<p>Ajouter photos</p>',
    eus: '<p>Gehitu argazkiak</p>',
    cat: '<p>Afegeix fotos</p>',
  },

  videodropzoneMessage: {
    es: '<p>Añadir vídeo</p>',
    en: '<p>Add a video</p>',
    pt: '<p>Adicionar vídeos</p>',
    fr: '<p>Ajouter vidéo</p>',
    eus: '<p>Gehitu bideoa</p>',
    cat: '<p>Afegeix vídeos</p>',
  },

  viewPhoto: {
    es: '<p>Ver foto</p>',
    en: '<p>View photo</p>',
    pt: '<p>Ver fotografia</p>',
    fr: '<p>Voir photo</p>',
    eus: '<p>Ikusi argazkia</p>',
    cat: '<p>Mostra foto</p>',
  },

  viewVideo: {
    es: '<p>Ver vídeo</p>',
    en: '<p>View video</p>',
    pt: '<p>Ver vídeo</p>',
    fr: '<p>Voir vidéo</p>',
    eus: '<p>Ikusi bideoa</p>',
    cat: '<p>Mostra vídeo</p>',
  },

  viewPhotoVideo: {
    es: '<p>Ver Foto/ Vídeo</p>',
    en: '<p>View Photo/Video</p>',
    pt: '<p>Ver fotografia/vídeo</p>',
    fr: '<p>Voir photo/vidéo</p>',
    eus: '<p>Ikusi argazkia / bideoa</p>',
    cat: '<p>Mostra foto / vídeo</p>',
  },

  fishEyeLensFirst: {
    es: '<p>Lente</p>',
    en: '<p>Fisheye lens</p>',
    pt: '<p>Lente</p>',
    fr: '<p>Objectif</p>',
    eus: '<p>lentea</p>',
    cat: '<p>Objectiu</p>',
  },

  fishEyeLensSecond: {
    es: '<p>Ojo de Pez</p>',
    en: '<p></p>',
    pt: '<p>Olho de peixe</p>',
    fr: '<p>Fisheye</p>',
    eus: '<p>Arrain-begi</p>',
    cat: '<p>Ull de peix</p>',
  },

  videoOptions: {
    es: '<p>Opciones de Vídeo</p>',
    en: '<p>Video Options</p>',
    pt: '<p>Opções de vídeo</p>',
    fr: '<p>Options vidéo</p>',
    eus: '<p>Bideoaren aukerak</p>',
    cat: '<p>Opcions de vídeo</p>',
  },

  viewResume: {
    es: '<p>Ver resumen</p>',
    en: '<p>See 3D model summary</p>',
    pt: '<p>Ver resumo</p>',
    fr: '<p>Visualiser le résumé</p>',
    eus: '<p>Ikusi 3D</p>',
    cat: '<p>Mostra el resum</p>',
  },

  viewResumeModel: {
    es: '<p>del modelo 3D</p>',
    en: '<p></p>',
    pt: '<p>do modelo 3D</p>',
    fr: '<p>du modèle 3D</p>',
    eus: '<p>ereduaren laburpena</p>',
    cat: '<p>del model 3D</p>',
  },

  pressTheButton: {
    es: '<p>Pulsa el botón</p>',
    en: '<p>Click on the button</p>',
    pt: '<p>Clique no botão</p>',
    fr: '<p>Cliquez sur le bouton</p>',
    eus: '<p>Sakatu botoia</p>',
    cat: '<p>Prem el botó</p>',
  },

  to: {
    es: '<p>para</p>',
    en: '<p>to</p>',
    pt: '<p>para</p>',
    fr: '<p>pour</p>',
    eus: '<p>aurrera</p>',
    cat: '<p>per</p>',
  },

  continue: {
    es: '<p>continuar</p>',
    en: '<p>continue</p>',
    pt: '<p>continuar</p>',
    fr: '<p>continuer</p>',
    eus: '<p>egiteko</p>',
    cat: '<p>continua</p>',
  },

  wouldYouLikeToMakeAFurtherActionBeforeProcessTheModel: {
    es: '<p>¿Deseas realizar alguna acción más antes de procesar el modelo 3D?</p>',
    en: '<p>Would you like to do anything else before processing the 3D model?</p>',
    pt: '<p>Pretende efetuar alguma ação adicional antes de processar o modelo 3D?</p>',
    fr: '<p>Souhaitez-vous faire autre chose avant de procéder au traitement du modèle 3D ?</p>',
    eus: '<p>Beste ekintzarik egin nahi duzu 3D eredua prozesatu aurretik?</p>',
    cat: '<p>Vols fer cap altra acció abans de processar el model 3D?</p>',
  },

  businessName: {
    es: '<p>Razón social</p>',
    en: '<p>Company name</p>',
    pt: '<p>Nome da empresa</p>',
    fr: '<p>Raison Sociale</p>',
    eus: '<p>Sozietate izena</p>',
    cat: '<p>Raó social</p>',
  },

  sendRequest: {
    es: '<p>Enviar solicitud</p>',
    en: '<p>Send request</p>',
    pt: '<p>Enviar pedido</p>',
    fr: '<p>Envoyer la demande</p>',
    eus: '<p>BIDALI ESKAERA</p>',
    cat: '<p>Envia sol·licitud</p>',
  },

  contactPerson: {
    es: '<p>Persona de contacto</p>',
    en: '<p>Contact person</p>',
    pt: '<p>Pessoa de contacto</p>',
    fr: '<p>Personne de contact</p>',
    eus: '<p>Harremanetarako pertsona</p>',
    cat: '<p>Persona de contacte</p>',
  },

  web: {
    es: '<p>Página web</p>',
    en: '<p>Website</p>',
    pt: '<p>Website</p>',
    fr: '<p>Page Web</p>',
    eus: '<p>Webgunea</p>',
    cat: '<p>Lloc web</p>',
  },

  loading: {
    es: '<p>Cargando</p>',
    en: '<p>Loading</p>',
    pt: '<p>A carregar</p>',
    fr: '<p>Chargement</p>',
    eus: '<p>Kargatzen</p>',
    cat: '<p>S\'està carregant</p>',
  },

  loadingSuccessful: {
    es: '<p>Cargado correctamente</p>',
    en: '<p>Loaded successfully</p>',
    pt: '<p>Carregado corretamente</p>',
    fr: '<p>Chargé correctement</p>',
    eus: '<p>Behar bezala kargatu da</p>',
    cat: '<p>S\'ha carregat correctament</p>',
  },

  addMorePhotos: {
    es: '<p>Añadir</p><p>más fotos</p>',
    en: '<p>Add</p><p>more photos</p>',
    pt: '<p>Adicionar</p><p>mais fotografias</p>',
    fr: '<p>Ajouter</p><p>d\'autres photos</p>',
    eus: '<p>Gehitu</p><p>argazki gehiago</p>',
    cat: '<p>Afegeix</p><p>més fotos</p>',
  },

  addMorePhotosTitle: {
    es: '<p>Añade más fotos</p>',
    en: '<p>Add more photos</p>',
    pt: '<p>Adicione mais fotografias</p>',
    fr: '<p>Ajoutez plus de photos</p>',
    eus: '<p>Gehitu argazki gehiago</p>',
    cat: '<p>Afegeix més fotos</p>',
  },

  zoomPhoto: {
    es: '<p>Ampliar foto</p>',
    en: '<p>Enlarge photo</p>',
    pt: '<p>Ampliar fotografia</p>',
    fr: '<p>Agrandir photo</p>',
    eus: '<p>Handitu argazkia</p>',
    cat: '<p>Amplia foto</p>',
  },

  blk: {
    es: '<p>Interior</p><p>/ BLK</p>',
    en: '<p>Interior</p><p>/ BLK</p>',
    pt: '<p>Interior</p><p>/ BLK</p>',
    fr: '<p>Intérieur</p><p>/ BLK</p>',
    eus: '<p>Barnealdeak</p><p>/ BLK</p>',
    cat: '<p>Interior</p><p>/ BLK</p>',
  },

  blkText: {
    es: '<p>Selecciona si las fotografías se han tomado en alguno de los siguientes casos:</p>',
    en: '<p>Select whether the photos were taken in one of these situations:</p>',
    pt: '<p>Selecione se as fotografias foram tiradas em algum dos seguintes casos:</p>',
    fr: '<p>Sélectionnez si les photos ont été prises dans une des situations suivantes :</p>',
    eus: '<p>Argazkiak honako egoeraren batean atera badituzu, egin hautaketa:</p>',
    cat: '<p>Selecciona si les fotos s’han fet en algun dels casos següents:</p>',
  },

  blkInside: {
    es: '<p>Fotografía en INTERIOR</p>',
    en: '<p>INTERIOR photo</p>',
    pt: '<p>Fotografia em INTERIOR</p>',
    fr: '<p>Photo en INTÉRIEUR</p>',
    eus: '<p>BARNEALDEKO argazkia</p>',
    cat: '<p>Fotografia en INTERIOR</p>',
  },

  blkPhoto: {
    es: '<p>Fotografía BLK</p>',
    en: '<p>BLK photo</p>',
    pt: '<p>Fotografia BLK</p>',
    fr: '<p>Photo BLK</p>',
    eus: '<p>BLK argazkia</p>',
    cat: '<p>Fotografia en BLK</p>',
  },

  modelSummary: {
    es: '<p>Resumen del modelo 3D</p>',
    en: '<p>3D model summary</p>',
    pt: '<p>Resumo do modelo 3D</p>',
    fr: '<p>Résumé du modèle 3D</p>',
    eus: '<p>3D ereduaren laburpena</p>',
    cat: '<p>Resum del model 3D</p>',
  },

  addMorePhotosText: {
    es: '<p>Añade más fotos</p>',
    en: '<p>Add more photos</p>',
    pt: '<p>Adicione mais fotografias</p>',
    fr: '<p>Ajoutez d\'autres photos</p>',
    eus: '<p>Gehitu argazki gehiago</p>',
    cat: '<p>Afegeix més fotos</p>',
  },

  addMoreVideosText: {
    es: '<p>Añadir</p><p>más vídeos</p>',
    en: '<p>Add</p><p>more videos</p>',
    pt: '<p>Adicionar</p><p>mais vídeos</p>',
    fr: '<p>Ajouter</p><p>d\'autres vidéos</p>',
    eus: '<p>Gehitu</p><p>bideo gehiago</p>',
    cat: '<p>Afegeix</p><p>més vídeos</p>',
  },

  toCompleteTheModel: {
    es: '<p>para completar el modelo 3D.</p>',
    en: '<p>to complete the 3D model.</p>',
    pt: '<p>para completar o modelo 3D.</p>',
    fr: '<p>pour compléter le modèle 3D.</p>',
    eus: '<p>3D eredua osatzeko.</p>',
    cat: '<p>per completar el model 3D.</p>',
  },

  deleteModelText: {
    es: '<p>Selecciona la foto que deseas eliminar. La foto se eliminará permanentemente</p>',
    en: '<p>Select the photo you wish to delete. The photo will be permanently deleted</p>',
    pt: '<p>Selecione a fotografia que pretende eliminar. A fotografia será eliminada permanentemente</p>',
    fr: '<p>Sélectionnez la photo que vous souhaitez éliminer. La photo sera éliminée de façon permanente</p>',
    eus: '<p>Ezabatu nahi duzun argazkia hautatu. Argazkia betiko ezabatuko da</p>',
    cat: '<p>Selecciona la foto que vols eliminar. La foto s’eliminarà de manera permanent</p>',
  },

  deleteModelWarning: {
    es: '<p>Esta acción NO se puede deshacer</p>',
    en: '<p>This action CANNOT be undone</p>',
    pt: '<p>Esta ação NÃO é reversível</p>',
    fr: '<p>Cette action NE PEUT PAS être annulée</p>',
    eus: '<p>Ekintza hau EZIN DA desegin</p>',
    cat: '<p>Aquesta acció NO es pot desfer</p>',
  },

  viewPhotoText: {
    es: '<p>Selecciona la foto / vídeo que deseas ampliar.</p>',
    en: '<p>Select the photo/video you wish to enlarge.</p>',
    pt: '<p>Selecione a fotografia / vídeo que pretende ampliar.</p>',
    fr: '<p>Sélectionnez la photo / vidéo que vous souhaitez agrandir.</p>',
    eus: '<p>Hautatu handitu nahi duzun argazkia / bideoa.</p>',
    cat: '<p>Selecciona la foto o el vídeo que vols ampliar.</p>',
  },

  eyeFishLensTitle: {
    es: '<p>Lente Ojo de Pez ACTIVADA</p>',
    en: '<p>Fisheye lens ACTIVATED</p>',
    pt: '<p>Lente Olho de Peixe ATIVADA</p>',
    fr: '<p>Objectif Fisheye ACTIVÉ</p>',
    eus: '<p>Arrain-begi lentea AKTIBATUTA</p>',
    cat: '<p>Objectiu ull de peix ACTIVAT</p>',
  },

  eyeFishLensText: {
    es: '<p>El vídeo se ha realizado con una lente Ojo de Pez.</p>',
    en: '<p>This video was shot using a fisheye lens.</p>',
    pt: '<p>O vídeo foi realizado com uma lente Olho de Peixe.</p>',
    fr: '<p>La vidéo a été réalisée avec un objectif Fisheye.</p>',
    eus: '<p>Bideoa arrain-begi lentea erabiliz grabatu da.</p>',
    cat: '<p>El vídeo s’ha fet amb un objectiu d’ull de peix.</p>',
  },

  maximumFilesErrorText: {
    es: '<p>Has superado el número máximo de fotos permitidas para la generación de modelo 3Dmodel. Elimina fotos o</p>',
    en: '<p>You have exceeded the maximum number of photos allowed to generate a 3D model. Please delete photos or</p>',
    pt: '<p>Ultrapassou o número máximo de fotografias permitidas para a criação de modelos 3Dmodel. Elimine fotografias ou</p>',
    fr: '<p>Vous avez dépassé le nombre maximal de photos permises pour la génération d\'un modèle 3D.Veuillez éliminer des photos ou</p>',
    eus: '<p>3Dmodel eredua sortzeko onartzen den argazki kopuruaren muga gailendu duzu. Ezabatu argazkiren bat edo</p>',
    cat: '<p>Has assolit la quantitat màxima de fotos permesa per generar el model 3Dmodel. Elimina fotos o</p>',
  },

  generatelargeModel: {
    es: '<p>genera un modelo 4Pro3Dmodel.</p>',
    en: '<p>generate a 4Pro3Dmodel.</p>',
    pt: '<p>crie um modelo 4Pro3Dmodel.</p>',
    fr: '<p>générer un modèle 4Pro3Dmodel.</p>',
    eus: '<p>sortu 4Pro3Dmodel eredua.</p>',
    cat: '<p>genera un model 4Pro3Dmodel.</p>',
  },

  toGeneratelargeModel: {
    es: '<p>generar un modelo 4Pro3Dmodel</p>',
    en: '<p>generate a 4Pro3Dmodel</p>',
    pt: '<p>criar um modelo 4Pro3Dmodel</p>',
    fr: '<p>générer un modèle 4Pro3Dmodel</p>',
    eus: '<p>Sortu 4PRO3DMODEL eredua</p>',
    cat: '<p>genera un model 4Pro3Dmodel</p>',
  },

  deletePhoto: {
    es: '<p>Eliminar foto</p>',
    en: '<p>Delete photo</p>',
    pt: '<p>Eliminar fotografia</p>',
    fr: '<p>Supprimer photo</p>',
    eus: '<p>Ezabatu argazkia</p>',
    cat: '<p>Elimina foto</p>',
  },

  deleteVideo: {
    es: '<p>Eliminar vídeo</p>',
    en: '<p>Delete video</p>',
    pt: '<p>Eliminar vídeo</p>',
    fr: '<p>Supprimer vidéo</p>',
    eus: '<p>Ezabatu bideoa</p>',
    cat: '<p>Elimina vídeo</p>',
  },

  delete: {
    es: '<p>Eliminar</p>',
    en: '<p>Delete</p>',
    pt: '<p>Eliminar</p>',
    fr: '<p>Supprimer</p>',
    eus: '<p>EZABATU</p>',
    cat: '<p>Elimina</p>',
  },

  uploadCalibration: {
    es: '<p>SUBIR CALIBRACIÓN</p>',
    en: '<p>UPLOAD CALIBRATION</p>',
    pt: '<p>CARREGAR CALIBRAÇÃO</p>',
    fr: '<p>TÉLÉCHARGER CALIBRATION</p>',
    eus: '<p>Kargatu kalibrazioa</p>',
    cat: '<p>CARREGA CALIBRATGE</p>',
  },

  uploadGps: {
    es: '<p>SUBIR GPS</p>',
    en: '<p>UPLOAD GPS</p>',
    pt: '<p>CARREGAR GPS</p>',
    fr: '<p>TÉLÉCHARGER GPS</p>',
    eus: '<p>KARGATU GPS-A</p>',
    cat: '<p>CARREGA GPS</p>',
  },

  uploadDataCalibration: {
    es: '<p>SUBIR DATOS DE CALIBRACIÓN</p>',
    en: '<p>UPLOAD CALIBRATION DATA</p>',
    pt: '<p>CARREGAR DADOS DE CALIBRAÇÃO</p>',
    fr: '<p>TÉLÉCHARGER DONNÉES DE CALIBRATION</p>',
    eus: '<p>Kargatu kalibrazio datuak</p>',
    cat: '<p>CARREGA DADES DE CALIBRATGE</p>',
  },

  uploadDataGps: {
    es: '<p>SUBIR GPS</p>',
    en: '<p>UPLOAD GPS</p>',
    pt: '<p>CARREGAR GPS</p>',
    fr: '<p>TÉLÉCHARGER GPS</p>',
    eus: '<p>KARGATU GPS-A</p>',
    cat: '<p>CARREGA GPS</p>',
  },

  addMoreDetailPhotos: {
    es: '<p>Añadir fotos de detalle</p>',
    en: '<p>Add detailed photos</p>',
    pt: '<p>Adicionar fotografias de detalhe</p>',
    fr: '<p>Ajouter photos de détail</p>',
    eus: '<p>Gehitu xehetasun argazkiak</p>',
    cat: '<p>Afegeix fotos de detall</p>',
  },

  credits: {
    es: '<p>Créditos</p>',
    en: '<p>Credits</p>',
    pt: '<p>Créditos</p>',
    fr: '<p>Crédits</p>',
    eus: '<p>Kredituak</p>',
    cat: '<p>Crèdits</p>',
  },

  blkDataDropzoneMessage: {
    es: '<p>Añadir datos</p>',
    en: '<p>Add data</p>',
    pt: '<p>Adicionar dados</p>',
    fr: '<p>Ajouter données</p>',
    eus: '<p>Gehitu datuak</p>',
    cat: '<p>Afegeix dades</p>',
  },


  blkDataDialogIntroText: {
    es: '<p>Sube o arrastra el documento de datos de calibración de Fotografía BLK</p>',
    en: '<p>Upload or drag and drop the BLK photo calibration data document</p>',
    pt: '<p>Carregue ou arraste o documento de dados de calibração de Fotografia BLK</p>',
    fr: '<p>Téléchargez ou glissez le document de données de calibration de la photo BLK</p>',
    eus: '<p>Kargatu edo arrastatu BLK argazkiaren kalibrazio datuen dokumentua</p>',
    cat: '<p>Carrega o arrossega el document de dades de calibratge de Fotografia BLK</p>',
  },

  blkDropzoneText: {
    es: '<p>Sube o arrastra el documento de datos de calibración de Fotografía BLK</p>',
    en: '<p>Upload or drag and drop the BLK photo calibration data document</p>',
    pt: '<p>Carregue ou arraste o documento de dados de calibração de Fotografia BLK</p>',
    fr: '<p>Téléchargez ou glissez le document de données de calibration de la photo BLK</p>',
    eus: '<p>Kargatu edo arrastatu BLK argazkiaren kalibrazio datuen dokumentua</p>',
    cat: '<p>Carrega o arrossega el document de dades de calibratge de Fotografia BLK</p>',
  },

  blkGpsDataDropzoneMessage: {
    es: '<p>Añadir GPS</p>',
    en: '<p>Add GPS</p>',
    pt: '<p>Adicionar GPS</p>',
    fr: '<p>Ajouter GPS</p>',
    eus: '<p>Gehitu GPS-a</p>',
    cat: '<p>Afegeix GPS</p>',
  },

  blkGpsDataDialogIntroText: {
    es: '<p>Sube o arrastra el documento de datos GPS de Fotografía BLK</p>',
    en: '<p>Upload or drag and drop the BLK photo GPS data document</p>',
    pt: '<p>Carregue ou arraste o documento de dados GPS de Fotografia BLK</p>',
    fr: '<p>Téléchargez ou glissez le document de données GPS de la photo BLK</p>',
    eus: '<p>Kargatu edo arrastatu BLK argazkiaren GPS datuen dokumentua</p>',
    cat: '<p>Carrega o arrossega el document de dades GPS de Fotografia BLK</p>',
  },

  blkErrorFirstLine: {
    es: '<p>El archivo de datos de calibración subido no es un archivo correcto</p>',
    en: '<p>The uploaded calibration data file is incorrect</p>',
    pt: '<p>O formato do ficheiro de dados de calibração carregado é incorreto</p>',
    fr: '<p>Le fichier de données de calibration téléchargé n\'est pas un fichier correct</p>',
    eus: '<p>Kargatu duzun kalibrazio datuen fitxategia ez da zuzena</p>',
    cat: '<p>El fitxer de dades de calibratge carregat no és un fitxer correcte</p>',
  },

  blkErrorSecondLine: {
    es: '<p>Revisa y vuelve a subir el archivo de <span class="i18n-bold">Datos de Calibración</span></p><p>con el formato correcto, obtenido del dispositivo.</p>',
    en: '<p>Check and re-upload the <span class="i18n-bold">Calibration Data</span></p><p>file in the correct format, obtained from the device.</p>',
    pt: '<p>Verifique e carregue novamente o ficheiro de <span class="i18n-bold">Dados de calibração</span></p><p> com o formato correto, obtido a partir do dispositivo.</p>',
    fr: '<p>Vérifiez et téléchargez à nouveau le fichier <span class="i18n-bold">Données de calibration</span></p><p> avec le format correct, obtenu du dispositif.</p>',
    eus: '<p>Zuzendu eta berriz ere kargatu <span class="i18n-bold">kalibrazio datuen</span> fitxategia,</p><p>gailutik ateratako formatu zuzenarekin.</p>',
    cat: '<p>Revisa i torna a carregar el fitxer de <span class="i18n-bold">dades de calibratge</span></p><p>amb el format correcte, obtingut del dispositiu.</p>',
  },

  blkErrorSecondReturnButton: {
    es: '<p>Volver a subir datos de calibración</p>',
    en: '<p>Re-upload calibration data</p>',
    pt: '<p>Carregar novamente dados de calibração</p>',
    fr: '<p>Téléchargez à nouveau les données de calibration</p>',
    eus: '<p>Berriro kargatu kalibrazio datuak</p>',
    cat: '<p>Torna a carregar les dades de calibratge</p>',
  },

  blkErrorGPSFirstLine: {
    es: '<p>El archivo de GPS subido no es un archivo correcto</p>',
    en: '<p>The uploaded GPS file is incorrect</p>',
    pt: '<p>O formato do ficheiro GPS carregado é incorreto</p>',
    fr: '<p>Le fichier GPS téléchargé n\'est pas un fichier correct</p>',
    eus: '<p>Kargatu duzun GPS fitxategia ez da zuzena</p>',
    cat: '<p>El fitxer de GPS carregat no és un fitxer correcte</p>',
  },

  blkErrorGPS: {
    es: '<p>Revisa y vuelve a subir el archivo de <span class="i18n-bold">GPS</span></p><p>con el formato correcto, obtenido del dispositivo.</p>',
    en: '<p>Check and re-upload the <span class="i18n-bold">GPS</span></p><p>file in the correct format, obtained from the device.</p>',
    pt: '<p>Verifique e carregue novamente o ficheiro de <span class="i18n-bold">GPS</span></p><p>com o formato correto, obtido a partir do dispositivo.</p>',
    fr: '<p>Vérifiez et téléchargez à nouveau le fichier <span class="i18n-bold">GPS</span></p><p>avec le format correct, obtenu du dispositif.</p>',
    eus: '<p>Zuzendu eta berriz ere kargatu <span class="i18n-bold">GPS</span> fitxategia,</p><p>gailutik ateratako formatu zuzenarekin.</p>',
    cat: '<p>Revisa i torna a carregar el fitxer de <span class="i18n-bold">GPS</span></p><p>amb el format correcte, obtingut del dispositiu.</p>',
  },

  blkErrorGPSSecondReturnButton: {
    es: '<p>Volver a subir GPS</p>',
    en: '<p>Re-upload GPS</p>',
    pt: '<p>Carregar novamente GPS</p>',
    fr: '<p>Téléchargez à nouveau le GPS</p>',
    eus: '<p>Berriz kargatu GPS-a</p>',
    cat: '<p>Torna a carregar GPS</p>',
  },

  successMessageText: {
    es: '<p>Solicitud enviada correctamente.</p><p>te daremos una respuesta en la mayor brevedad posible.</p>',
    en: '<p>Your request was sent correctly.</p><p>We will reply as soon as possible.</p>',
    pt: '<p>Pedido enviado corretamente.</p><p>Dar-lhe-emos uma resposta o mais brevemente possível.</p>',
    fr: '<p>Demande envoyée correctement.</p><p>Nous vous répondrons dans les meilleurs délais.</p>',
    eus: '<p>Zure eskaera ondo bidali da</p><p>gure erantzuna ahalik eta lasterren bidaliko dizugu.</p>',
    cat: '<p>Sol·licitud enviada correctament</p><p>et donarem una resposta al més aviat.</p>',
  },

  companyRequestTitle: {
    es: '<p>Solicitud empresa</p>',
    en: '<p>Company request</p>',
    pt: '<p>Pedido empresa</p>',
    fr: '<p>Demande statut entreprise</p>',
    eus: '<p>ENPRESA ESKAERA</p>',
    cat: '<p>Sol·licitud d\'empresa</p>',
  },

  companyRequestText: {
    es: '<p>Panel de control de gestión, usuarios ilimitados, departamentos, estadísticas…</p><p>Todo usuario empresa necesita mínimo 2 usuarios administradores</p>',
    en: '<p>Management control panel, unlimited users, departments, statistics, etc.</p><p>All company users require at least 2 administrator users</p>',
    pt: '<p>Painel de controlo de gestão, utilizadores ilimitados, departamentos, estatísticas, …</p><p>Qualquer utilizador de empresa requer, no mínimo, 2 utilizadores administradores</p>',
    fr: '<p>Tableau de bord de gestion, utilisateurs illimités, départements, statistiques…</p><p>Tout utilisateur entreprise doit avoir au moins 2 utilisateurs administrateurs</p>',
    eus: '<p>Kudeaketarako kontrol panela, mugarik gabeko erabiltzaile kopurua, departamentuak, estatistikak...</p><p>Enpresa erabiltzaile orok 2 administratzaile erabiltzaile behar ditu, gutxienez</p>',
    cat: '<p>Tauler de control de gestió, usuaris il·limitats, departaments, estadístiques…</p><p>Tot usuari empresa necessita com a mínim 2 usuaris administradors</p>',
  },

  successMessageButton: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  closeRequestWindow: {
    es: '<p>Cerrar</p>',
    en: '<p>Close</p>',
    pt: '<p>Fechar</p>',
    fr: '<p>Fermer</p>',
    eus: '<p>ITXI</p>',
    cat: '<p>Tanca</p>',
  },

  fishEyeQuestion: {
    es: '<p>¿Se ha utilizado una Lente Ojo de Pez?</p>',
    en: '<p>Was a fisheye lens used?</p>',
    pt: '<p>Foi utilizada uma lente Olho de Peixe?</p>',
    fr: '<p>A-t-on utilisé un objectif Fisheye ?</p>',
    eus: '<p>Arrain-begi lentea erabili da?</p>',
    cat: '<p>S’ha emprat un objectiu d’ull de peix?</p>',
  },

  yes: {
    es: '<p>Sí</p>',
    en: '<p>Yes</p>',
    pt: '<p>Sim</p>',
    fr: '<p>Oui</p>',
    eus: '<p>Bai</p>',
    cat: '<p>Sí</p>',
  },

  videoSpeedQuestion: {
    es: '<p>¿Se ha grabado el vídeo a una velocidad superior a +30km/h?</p>',
    en: '<p>Was the video recorded at a speed of over 30 km/h?</p>',
    pt: '<p>O vídeo foi gravado a uma velocidade superior a 30 km/h?</p>',
    fr: '<p>La vidéo a-t-elle été enregistrée à une vitesse supérieure à 30km/h ?</p>',
    eus: '<p>Bideoa 30 km/h baino abiadura handiagoan grabatu al da?</p>',
    cat: '<p>S’ha fet el vídeo a una velocitat superior a +30 km/h?</p>',
  },

  modelType: {
    es: '<p>Tipo de Modelo 3D</p>',
    en: '<p>3D Model Type</p>',
    pt: '<p>Tipo de Modelo 3D</p>',
    fr: '<p>Type de modèle 3D</p>',
    eus: '<p>3D eredu mota</p>',
    cat: '<p>Tipus de model 3D</p>',
  },

  modelTypeLargeText: {
    es: '<p>Selecciona qué tipo de modelo 3D se va a realizar</p>',
    en: '<p>Select the type of 3D model you are going to make</p>',
    pt: '<p>Selecione o tipo de modelo 3D a criar</p>',
    fr: '<p>Sélectionnez le type de modèle 3D à effectuer</p>',
    eus: '<p>Aukeratu zer 3D eredu mota sortu nahi duzun</p>',
    cat: '<p>Selecciona quin tipus de model 3D es generarà</p>',
  },

  face: {
    es: '<p>Cara</p>',
    en: '<p>Face</p>',
    pt: '<p>Rosto</p>',
    fr: '<p>Visage</p>',
    eus: '<p>Garestia</p>',
    cat: '<p>Cara</p>',
  },

  road: {
    es: '<p>Carretera</p>',
    en: '<p>Road</p>',
    pt: '<p>Estrada</p>',
    fr: '<p>Route</p>',
    eus: '<p>Errepidea</p>',
    cat: '<p>Carretera</p>',
  },

  object: {
    es: '<p>Objeto</p>',
    en: '<p>Object</p>',
    pt: '<p>Objeto</p>',
    fr: '<p>Objet</p>',
    eus: '<p>Objektua</p>',
    cat: '<p>Objecte</p>',
  },

  body: {
    es: '<p>Cuerpo</p>',
    en: '<p>Body</p>',
    pt: '<p>Corpo</p>',
    fr: '<p>Corps</p>',
    eus: '<p>Gorputza</p>',
    cat: '<p>Cos</p>',
  },

  vehicle: {
    es: '<p>Vehículo</p>',
    en: '<p>Vehicle</p>',
    pt: '<p>Veículo</p>',
    fr: '<p>Véhicule</p>',
    eus: '<p>Ibilgailua</p>',
    cat: '<p>Vehicle</p>',
  },

  tryingModel3D: {
    es: '<p>Estás intentando generar un modelo 4Pro3Dmodel con una cantidad inferior a 50 fotos o 1 minutos de vídeo.</p>',
    en: '<p>You are trying to generate a 4Pro3Dmodel with less than 50 photos or 1 minute of video.</p>',
    pt: '<p>Está a tentar criar um modelo 4Pro3Dmodel com uma quantidade inferior a 50 fotografias ou 1 minuto de vídeo.</p>',
    fr: '<p>Vous êtes en train d’essayer de générer un modèle 4Pro3Dmodel avec un nombre inférieur à 50 photos ou une vidéo de 1 minute.</p>',
    eus: '<p>4Pro3Dmodel eredua sortzen saiatzen ari zara, 50 argazki baino gutxiagorekin edo minutu 1 baino gutxiagoko bideoarekin.</p>',
    cat: '<p>Estàs intentant generar un model 4Pro3Dmodel amb una quantitat inferior a 50 fotos o 1 minut de vídeo.</p>',
  },

  wishContinue: {
    es: '<p>¿Deseas continuar?</p>',
    en: '<p>Do you wish to continue?</p>',
    pt: '<p>Pretende continuar?</p>',
    fr: '<p>Souhaitez-vous continuer ?</p>',
    eus: '<p>Jarraitu nahi duzu?</p>',
    cat: '<p>Vols continuar?</p>',
  },

  downloadNotInclude: {
    es: '<p>Esta opción no incluye descarga</p>',
    en: '<p>This option does not include downloads</p>',
    pt: '<p>Esta opção não inclui transferência</p>',
    fr: '<p>Cette option ne comprend pas le téléchargement</p>',
    eus: '<p>Aukera honekin ezin da deskargarik egin</p>',
    cat: '<p>Aquesta opció no inclou baixada</p>',
  },

  morePhotosProModel: {
    es: '<p>Añadir más fotos a mi modelo 4Pro3Dmodel</p>',
    en: '<p>Add more photos to my 4Pro3Dmodel</p>',
    pt: '<p>Adicionar mais fotografias ao meu modelo 4Pro3Dmodel</p>',
    fr: '<p>Ajouter des photos à mon modèle 4Pro3Dmodel</p>',
    eus: '<p>Gehitu argazki gehiago nire 4Pro3Dmodel eredura</p>',
    cat: '<p>Afegeix més fotos al meu model 4Pro3Dmodel</p>',
  },

  continueProModel: {
    es: '<p>CONTINUAR CON MODELO 4Pro3Dmodel</p>',
    en: '<p>CONTINUE WITH 4Pro3Dmodel</p>',
    pt: '<p>CONTINUAR COM MODELO 4Pro3Dmodel</p>',
    fr: '<p>CONTINUER AVEC UN MODÈLE 4Pro3Dmodel</p>',
    eus: '<p>JARRAITU 4Pro3Dmodel EREDUAREKIN</p>',
    cat: '<p>CONTINUA AMB MODEL 4Pro3Dmodel</p>',
  },

  addMorePhotoModel: {
    es: '<p>Añade más fotos para completar el modelo 3D</p>',
    en: '<p>Add more photos to complete the 3D model</p>',
    pt: '<p>Adicione mais fotografias para completar o modelo 3D</p>',
    fr: '<p>Ajoutez d\'autres photos pour compléter le modèle 3D</p>',
    eus: '<p>Gehitu argazki gehiago 3D eredua osatzeko</p>',
    cat: '<p>Afegeix més fotos per completar el model 3D</p>',
  },

  gainMorePrecise: {
    es: '<p>y obtener más precisión en el mismo</p>',
    en: '<p>and make it more accurate</p>',
    pt: '<p>e obter maior precisão no mesmo</p>',
    fr: '<p>et obtenir plus de précision dans le modèle</p>',
    eus: '<p>eta bertan xehetasun gehiago lortzeko</p>',
    cat: '<p>i obtén més precisió en ell</p>',
  },

  toContinueGenerate: {
    es: '<p>Para continuar,</p><p>escribe un nombre para tu modelo 3D</p><p>en el campo "Nuevo Modelo 3D" .</p>',
    en: '<p>To continue,</p><p>enter a name for your new model</p><p>in the "New 3D Model" field.</p>',
    pt: '<p>Para continuar,</p><p>escreva um nome para o seu modelo 3D</p><p>no campo “Novo Modelo 3D”.</p>',
    fr: '<p>Pour continuer,</p><p>saisissez un nom pour votre modèle 3D</p><p>dans le champ « Nouveau Modèle 3D ».</p>',
    eus: '<p>Jarraitzeko, idatzi</p><p>izen bat zure 3D ereduarentzat</p><p>"3D eredu berria" eremuan.</p>',
    cat: '<p>Per continuar,</p><p>escriu un nom per al model 3D</p><p>en el camp “Nou model 3D”</p>',
  },

  wishToDelete: {
    es: '<p>¿Quieres eliminar el archivo</p>',
    en: '<p>Do you want to delete the file</p>',
    pt: '<p>Pretende eliminar o ficheiro</p>',
    fr: '<p>Souhaitez-vous éliminer le fichier</p>',
    eus: '<p>Ezabatu nahi al duzu honako izena duen fitxategia:</p>',
    cat: '<p>Vols eliminar el fitxer?</p>',
  },

  deleteFileWarning: {
    es: '<p>Al eliminar este archivo también se eliminarán sus datos.</p>',
    en: '<p>If you delete this file, you will also delete the corresponding data.</p>',
    pt: '<p>Ao eliminar este ficheiro, eliminar-se-ão também os dados do mesmo.</p>',
    fr: '<p>Si vous supprimez ce fichier, vous supprimerez également toutes les données qu\'il contient.</p>',
    eus: '<p>Fitxategi hau ezabatzean, honen datu guztiak ere ezabatuko dira.</p>',
    cat: '<p>Si elimines aquest fitxer, també s’eliminaran les seves dades.</p>',
  },

  deleting: {
    es: '<p>Eliminando</p>',
    en: '<p>Deleting</p>',
    pt: '<p>A eliminar</p>',
    fr: '<p>Suppression</p>',
    eus: '<p>Ezabatzen</p>',
    cat: '<p>S’està eliminant</p>',
  },

  maximumFilesDetailErrorText: {
    es: '<p>Has superado el número máximo de fotos de detalle permitidas para la generación del modelo 3D.</p>',
    en: '<p>You have exceeded the maximum number of photos allowed to generate a 3D model.</p>',
    pt: '<p>Ultrapassou o número máximo de fotografias de detalhe permitidas para a criação do modo 3D.</p>',
    fr: '<p>Vous avez dépassé le nombre maximal de photos de détail permises pour la génération d\'un modèle 3D.</p>',
    eus: '<p>3D eredua sortzeko onartzen den xehetasun argazki kopuruaren muga gailendu duzu.</p>',
    cat: '<p>Has assolit la quantitat màxima de fotos de detall permesa per generar el model 3D.</p>',
  },

  maximumFilesDetailErrorTextBold: {
    es: '<p>Elimina hasta tener un total máximo de 15 fotos de detalle.</p>',
    en: '<p>Delete images until you have a maximum of 15 detailed photos.</p>',
    pt: '<p>Elimine até ter um total máximo de 15 fotografias de detalhe.</p>',
    fr: '<p>Supprimez des photos de détail afin d\'en avoir 15 au maximum.</p>',
    eus: '<p>Ezabatu irudiak gehienez 15 xehetasun argazki izan arte.</p>',
    cat: '<p>Elimina\'n fins a tenir un màxim total de 15 fotos de detall.</p>',
  },

  toContinueGenerate0: {
    es: '<p>Para continuar, escribe un <span class="i18n-bold">nombre<span class="i18n-bold"></p><p>para tu modelo 3D en el campo <p class="i18n-bold">"Nuevo Modelo 3D".</p></p>',
    en: '<p>To continue, enter a <span class="i18n-bold">name<span class="i18n-bold"></p><p>for your new model in the <p class="i18n-bold">"New 3D Model" field.</p></p>',
    pt: '<p>Para continuar, escreva um <span class="i18n-bold">nome<span class="i18n-bold"></p><p>para o seu modelo 3D no campo <p class="i18n-bold">"Novo Modelo 3D".</p></p>',
    fr: '<p>Pour continuer, saisissez un <span class="i18n-bold">nom<span class="i18n-bold"></p><p>pour votre modèle 3D dans le champ <p class="i18n-bold">« Nouveau Modèle 3D ».</p></p>',
    eus: '<p>Jarraitzeko, idatzi <span class="i18n-bold">izena<span class="i18n-bold"></p><p>zure 3D ereduarentzat, <p class="i18n-bold">"3D eredu berria" eremuan.</p></p>',
    cat: '<p>Per continuar, escriu un <span class="i18n-bold">nom<span class="i18n-bold"></p><p>per al model 3D en el camp <p class="i18n-bold">“Nou model 3D”.</p></p>',
  },

  toContinueGenerateName: {
    es: '<p>nombre</p>',
    en: '<p>name</p>',
    pt: '<p>nome</p>',
    fr: '<p>nom</p>',
    eus: '<p>izena</p>',
    cat: '<p>nom</p>',
  },

  generateMinFiles: {
    es: '<p>Para generar un modelo 3Dmodel</p><p>debes subir mínimo 5 fotografías.</p>',
    en: '<p>You must upload at least 5 photos</p><p>to generate a 3D model.</p>',
    pt: '<p>Para criar um modelo 3Dmodel,</p><p>deve carregar um mínimo de 5 fotografias.</p>',
    fr: '<p>Vous devez télécharger un minimum de 5 photos</p><p>pour générer un modèle 3Dmodel.</p>',
    eus: '<p>3Dmodel eredu bat sortzeko,</p><p>gutxienez 5 argazki kargatu behar dituzu.</p>',
    cat: '<p>Per genera un model 3Dmodel,</p><p>has de carregar 5 fotografies com a mínim.</p>',
  },

  uploadMorePhotos: {
    es: '<p>SUBIR MÁS FOTOGRAFÍAS</p>',
    en: '<p>UPLOAD MORE PHOTOS</p>',
    pt: '<p>CARREGAR MAIS FOTOGRAFIAS</p>',
    fr: '<p>TÉLÉCHARGER D’AUTRES PHOTOS</p>',
    eus: '<p>KARGATU ARGAZKI GEHIAGO</p>',
    cat: '<p>CARREGA MÉS FOTOGRAFIES</p>',
  },

  minFilesNeeded: {
    es: '<p>(Entre 5 y 50 fotografías o hasta 1 min de vídeo)</p>',
    en: '<p>(From 5 to 50 photos or up to 1 min of video)</p>',
    pt: '<p>(Entre 5 e 50 fotografias ou até 1 min de vídeo)</p>',
    fr: '<p>(De 5 à 50 photos ou vidéo de 1 min maximum)</p>',
    eus: '<p>(5 eta 50 argazki bitarte edo gehienez 1 min-ko bideoa)</p>',
    cat: '<p>(Entre 5 i 50 fotografies o fins a 1 min de vídeo)</p>',
  },

  zoomPhotoVideo: {
    es: '<p>Ampliar foto / vídeo</p>',
    en: '<p>Enlarge photo/video</p>',
    pt: '<p>Ampliar fotografia/vídeo</p>',
    fr: '<p>Agrandir photo/vidéo</p>',
    eus: '<p>Handitu argazkia / bideoa</p>',
    cat: '<p>Amplia foto o vídeo</p>',
  },

  maximumMinutesErrorText: {
    es: '<p>Has superado el número máximo de minutos permitidos para la generación de modelo 3Dmodel. Sube máximo 1 minuto de duración o</p>',
    en: '<p>The video is longer than the maximum duration allowed to generate a 3D model. Upload a video lasting a maximum of 1 minute or</p>',
    pt: '<p>Ultrapassou o número máximo de minutos permitidos para a criação de modelo 3Dmodel. Carregue um vídeo com um máximo de 1 minuto de duração ou</p>',
    fr: '<p>Vous avez dépassé le nombre maximal de minutes permis pour la génération d\'un modèle 3Dmodel.Téléchargez 1 minute de vidéo maximum ou</p>',
    eus: '<p>3Dmodel eredua sortzeko onartzen den minutu kopuruaren muga gailendu duzu. Kargatu gehienez 1 min-ko iraupena edo</p>',
    cat: '<p>Has assolit la quantitat màxima de minuts permesa per generar el model 3Dmodel. Carrega com a màxim 1 minut de durada o</p>',
  },

  noProfessionalNoticeText12: {
    es: '<p>Cancelar</p>',
    en: '<p>Cancel</p>',
    pt: '<p>Cancelar</p>',
    fr: '<p>Annuler</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>Cancel·la</p>',
  },

  businessNIF: {
    es: '<p>NIF</p>',
    en: '<p>Tax ID</p>',
    pt: '<p>NIF</p>',
    fr: '<p>NIF</p>',
    eus: '<p>IFZ</p>',
    cat: '<p>NIF</p>',
  },

  maxFilesExcess_Data_1: {
    en: '<p>We have confirmed that you have uploaded <span class="i18n-bold">more than 200 photographs</p><p>or 4 minutes of video</span>.</p><p>You must delete the photographs or reduce the video duration to be</p><p>under the limits.</p>',
    es: '<p>Hemos comprobado que has subido <span class="i18n-bold">más de 200 fotografías</span></p><p>o <span class="i18n-bold">4 minutos de vídeo.</span></p><p>Debes eliminar aquellas fotografías o duración para estar</p><p>por debajo de los límites.</p>',
    pt: '<p>Comprovámos que carregou <span class="i18n-bold">mais de 200 fotografias</span></p><p>ou <span class="i18n-bold">4 minutos de vídeo.</span></p><p>Deve eliminar fotografias ou duração de vídeo para estar</p><p>abaixo dos limites.</p>',
    fr: '<p>Nous avons détecté que vous avez téléchargé <span class="i18n-bold">plus de 200</span> photos</p><p>ou <span class="i18n-bold">4 minutes de vidéo.</span></p><p>Vous devez supprimer des photos ou réduire la durée des vidéos</p><p>pour rester en dessous des limites.</p>',
    eus: '<p>Ikusi dugu <span class="i18n-bold">200 argazki baino gehiago</span> edo</p><p><span class="i18n-bold">4 minutu baino gehiagoko bideoa</span> kargatu duzula.</p><p>Argazki batzuk ezabatu edo iraupena murriztu,</p><p>onartutako mugen barruan egoteko.</p>',
    cat: '<p>Hem comprovat que has pujat <span class="i18n-bold">més de 200 fotografies</span></p><p>o <span class="i18n-bold">4 minuts de vídeo</span>.</p><p>Has d\'eliminar l\excés de fotos o durada per estar</p><p>per sota dels límits.</p>',
  },

  maxFilesExcess_Data_2: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  noProfessionalNoticeText1_1: {
    es: '<p>Tu usuario no es un usuario profesional.</p>',
    en: '<p>You are not a professional user.</p>',
    pt: '<p>O seu utilizador não é um utilizador profissional.</p>',
    fr: '<p>Votre utilisateur n’est pas un utilisateur professionnel.</p>',
    eus: '<p>Zure erabiltzailea ez da erabiltzaile profesionala.</p>',
    cat: '<p>El teu usuari no és un usuari professional.</p>',
  },

  noProfessionalNoticeText1_2: {
    es: '<p>Solo puedes crear modelos 3D de entre</p><p>5 y 50 fotografías o hasta 1 min de vídeo.</p>',
    en: '<p>You may only create 3D models from</p><p>5 to 50 photos or up to 1 min of video.</p>',
    pt: '<p>Só pode criar modelos 3D de entre</p><p>5 e 50 fotografias ou até 1 min de vídeo.</p>',
    fr: '<p>Vous ne pouvez créer que des modèles 3D de</p><p>5 à 50 photos ou vidéo de 1 min maximum.</p>',
    eus: '<p>3D ereduak sortzeko soilik 5 eta 50 argazki bitarte</p><p>edo gehienez 1 min - ko bideoa erabili ditzakezu.</p>',
    cat: '<p>Només pots crear models 3D d’entre</p><p>5 i 50 fotografies o fins a 1 min de vídeo.</p>',
  },

  noProfessionalNoticeText1_3: {
    es: '<p>¿Deseas actualizar tu usuario a usuario profesional?</p>',
    en: '<p>Do you want to upgrade to professional user?</p>',
    pt: '<p>>Pretende atualizar o seu utilizador para um utilizador profissional?</p>',
    fr: '<p>Souhaitez-vous devenir utilisateur professionnel ?</p>',
    eus: '<p>Zure erabiltzaile mota hobetu nahi erabiltzaile profesionalera?</p>',
    cat: '<p>Vols actualitzar el teu usuari a usuari professional?</p>',
  },

  noProfessionalNoticeText1_4: {
    es: '<p>Crea modelos 3D de entre 50 y 200 fotografías</p><p>o hasta 4 min de vídeo.</p>',
    en: '<p>Create 3D models from 50 to 200 photographs</p><p>or up to 4 min of video.</p>',
    pt: '<p>Crie modelos 3D de entre 50 e 200 fotografias</p><p>ou até 4 min de vídeo.</p>',
    fr: '<p>Créez des modèles 3D de 50 à 200 photos</p><p>ou vidéo de 4 min maximum.</p>',
    eus: '<p>Sortu 3D ereduak 50 eta 200 argazki bitarte edo gehienez</p><p>4 minutuko bideoa erabiliz</p>',
    cat: '<p>Crea models 3D d’entre 50 i 200 fotografies</p><p>o fins a 4 min de vídeo.</p>',
  },

  noProfessionalNoticeText1_5: {
    es: '<p>ACTUALIZAR USUARIO PROFESIONAL</p>',
    en: '<p>UPGRADE TO PROFESSIONAL USER</p>',
    pt: '<p>ATUALIZAR UTILIZADOR PROFISSIONAL</p>',
    fr: '<p>DEVENIR UTILISATEUR PROFESSIONNEL</p>',
    eus: '<p>HOBETU ERABILTZAILE PROFESIONALERA</p>',
    cat: '<p>ACTUALITZA A USUARI PROFESSIONAL</p>',
  },

  noProfessionalNoticeText1_6: {
    es: '<p>¿Deseas darte de alta como Usuario Empresa?</p>',
    en: '<p>Would you like to register as a Company User?</p>',
    pt: '<p>Pretende registar-se como Utilizador de Empresa?</p>',
    fr: '<p>Souhaitez-vous devenir utilisateur entreprise ?</p>',
    eus: '<p>Enpresa erabiltzaile gisa alta eman nahi duzu?</p>',
    cat: '<p>Vols donar-te d’alta com a usuari Empresa?</p>',
  },

  noProfessionalNoticeText1_7: {
    es: '<p>Usuarios ilimitados, departamentos,</p><p>estadísticas…</p>',
    en: '<p>You\'ll get unlimited users, departments,</p><p>statistics, and more.</p>',
    pt: '<p>Utilizadores ilimitados, departamentos</p><p>estatísticas, …</p>',
    fr: '<p>Utilisateurs illimités, départements,</p><p>statistiques…</p>',
    eus: '<p>Mugarik gabeko erabiltzaile kopurua, departamentuak</p><p>estatistikak,…</p>',
    cat: '<p>Usuaris il·limitats, departaments</p><p>estadístiques…</p>',
  },

  noProfessionalNoticeText1_8: {
    es: '<p>SOLICITAR USUARIO EMPRESA</p>',
    en: '<p>REQUEST COMPANY USER</p>',
    pt: '<p>PEDIR UTILIZADOR DE EMPRESA</p>',
    fr: '<p>DEMANDE STATUT UTILISATEUR ENTREPRISE</p>',
    eus: '<p>ESKATU ENPRESA ERABILTZAILEA</p>',
    cat: '<p>SOL·LICITA USUARI EMPRESA</p>',
  },

  new3d_Data45: {
    es: '<p>Has alcanzado el límite máximo de minutos permitidos para</p><p>generar un modelo 3D sin suscripción. Puedes subir un</p><p>máximo de 2 minutos de duración o suscribirte a alguna de</p><p>nuestras tarifas.</p>',
    en: '<p>You have exceeded the maximum number of minutes allowed</p><p>to generate a 3D model without a subscription. You can upload a</p><p>maximum of 2 minutes in duration or subscribe to one of</p><p>our plans.</p>',
    pt: '<p>Ultrapassou o número máximo de minutos permitidos para</p><p>a criação de um modelo 3D sem subscrição.Pode carregar no</p><p>máximo 2 minutos ou subscrever uma das</p><p>nossas tarifas.</p>',
    fr: '<p>Vous avez dépassé la limite maximum de minutes autorisées pour</p><p>générer un modèle 3D sans abonnement.Vous pouvez soit télécharger un</p><p>maximum de 2 minutes, soit vous</p><p>souscrire à l’un de</p><p>nos abonnements.</p>',
    eus: '<p>Harpidetzarik gabe 3D eredua sortzeko onartzen den minutu</p><p>kopuruaren mugara heldu zara. Gehienez 2 minutuko</p><p>iraupena duen bideoa kargatu dezakezu edo gure tarifetako batean</p><p>harpidetu zaitezke.</p>',
    cat: '<p>Has assolit la quantitat màxima de minuts permesos</p><p>per generar un model 3D sense subscripció. Pots carregar un</p><p>màxim de 2 minuts de durada o subscriure’t a alguna de</p><p>les nostres tarifes.</p>',
  },

  new3d_Data46: {
    es: '<p>CONTRATAR TARIFAS</p>',
    en: '<p>SUBSCRIPTION PLANS</p>',
    pt: '<p>CONTRATAR TARIFAS</p>',
    fr: '<p>SOUSCRIRE UN ABONNEMENT</p>',
    eus: '<p>KONTRATATU TARIFAK</p>',
    cat: '<p>CONTRACTA TARIFES</p>',
  },

  new3d_Data47: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  new3d_Data48: {
    es: '<p>Has superado el número máximo de fotos detalle permitidas para la generación de modelo 3D. <span class="i18n-bold">Elimina hasta tener un total máximo de 15 fotos de detalle.</span></p>',
    en: '<p>You have exceeded the maximum number of detailed photos allowed to generate a 3D model. <span class="i18n-bold">Delete images until you have a maximum of 15 detailed photos.</span></p>',
    pt: '<p>Ultrapassou o número máximo de fotografias de detalhe permitidas para a criação do modo 3D. <span class="i18n-bold">Elimine até ter um total máximo de 15 fotografias de detalhe.</span></p>',
    fr: '<p>Vous avez dépassé le nombre maximal de photos de détail permises pour la génération d\'un modèle 3D. <span class="i18n-bold">Supprimez des photos de détail afin d’en avoir 15 au maximum.</span></p>',
    eus: '<p>3D eredua sortzeko onartzen den xehetasun argazki kopuruaren muga gailendu duzu. <span class="i18n-bold">Ezabatu irudiak gehienez 15 xehetasun argazki izan arte.</span></p>',
    cat: '<p>Has assolit la quantitat màxima de fotos de detall permesa per generar el model 3D. <span class="i18n-bold">Elimina’n fins a tenir un màxim total de 15 fotos de detall.</span></p>',
  },

  new3d_Data49: {
    es: '<p><span class="i18n-bold">Para generar un modelo 3D debes subir mínimo 5 fotografías o un vídeo de al menos 5 segundos.</span></p>',
    en: '<p><span class="i18n-bold">To generate a 3D model you must upload at least 5 photos or a video lasting at least 5 seconds.</span></p>',
    pt: '<p><span class="i18n-bold">Para criar um modelo 3D, tem de carregar, pelo menos, 5 fotografias ou um vídeo de, pelo menos, 5 segundos.</span></p>',
    fr: '<p><span class="i18n-bold">Pour générer un modèle 3D, vous devez télécharger un minimum de 5 photos ou une vidéo d’au moins 5 secondes.</span></p>',
    eus: '<p><span class="i18n-bold">3D eredu bat sortzeko, gutxienez 5 argazki edo 5 segundoko bideo bat igo behar dituzu.</span></p>',
    cat: '<p><span class="i18n-bold">Per generar un model 3D has de pujar com a mínim 5 fotografies o un vídeo d\'almenys 5 segons.</span></p>',
  },
};
