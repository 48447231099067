import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-max-extension-file-dialog',
  templateUrl: './max-extension-file-dialog.component.html',
  styleUrls: ['./max-extension-file-dialog.component.scss'],
})
export class MaxExtensionFileDialogComponent implements OnInit {
  text: string;
  extension: string;

  constructor(
    public dialogRef: MatDialogRef<MaxExtensionFileDialogComponent>,
    private i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: { format: string }
  ) {}

  ngOnInit(): void {
    //Falta lógica para traer qué tipo de extensión se ha detectado
    this.extension = this.data.format;
    this.text = this.i18nService.getText('maxExtensionFiles_Data1', [
      TranslationModeEnum.INSERT_VALUE,
      this.extension,
    ]);
  }

  close(): void {
    this.dialogRef.close();
  }
}
