import { ChildrenOutletContexts, Route } from '@angular/router';
import { LayoutComponent } from '../commons/components/layout/layout.component';
import { PermissionGuard } from '../services/security/guards/auth.guard';
import { InformationPageComponent } from '../components/information-page/information-page.component';
import { IotaDownloadPdfComponent } from '../components/iota-pdf/iota-download-pdf/iota-download-pdf.component';
import { IotaImagePdfComponent } from '../components/iota-pdf/iota-image-pdf/iota-image-pdf.component';
import { IotaProcessPdfComponent } from '../components/iota-pdf/iota-process-pdf/iota-process-pdf.component';
import { SelectionAcceptedComponent } from '../components/selection-validation/selection-accepted/selection-accepted.component';
import { SelectionDeniedComponent } from '../components/selection-validation/selection-denied/selection-denied.component';
import { HomeComponent } from '../components/home/home.component';
import { ViewprojectComponent } from '../viewproject/components/viewproject/viewproject.component';
import { LimitVersionGuard } from '../services/security/guards/limit-version.guard';
import { MobileNoProfessionalNoticeComponent } from '../components/mobile-no-professional-notice/mobile-no-professional-notice.component';
import { MaintenanceComponent } from '../commons/components/maintenance/maintenance.component';
import { JoinRequestActionComponent } from '../commons/components/join-request-action/join-request-action.component';
import { TermsOfUseComponent } from '../components/information-text/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from '../components/information-text/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from '../components/information-text/cookies-policy/cookies-policy.component';
import { InformationPageEnum } from '../components/information-text/enum/information-page-enum';
import { HiringComponent } from '../components/hiring/hiring.component';
import { ModelNotFoundComponent } from '../commons/components/model-not-found/model-not-found.component';
import { ModelAccessibilityGuard } from '../services/security/guards/model-accessibility.guard';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // {
      //   path: 'home',
      //   redirectTo: '',
      //   pathMatch: 'full',
      // },
      {
        path: '',
        component: HomeComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'hiring',
        component: HiringComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'hiring/:rate',
        component: HiringComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'new3d',
        loadChildren: () =>
          import('../new-3d/new-3d.module').then((m) => m.New3dModule),
      },
      {
        path: 'models-processing',
        loadChildren: () =>
          import('../models-in-process/models-in-process.module').then(
            (m) => m.ModelsInProcessModule
          ),
      },
      {
        path: 'gallery',
        loadChildren: () =>
          import('../gallery/gallery.module').then((m) => m.GalleryModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'model/:code',
        loadChildren: () =>
          import('../viewproject/viewproject.module').then(
            (m) => m.ViewprojectModule
          ),
        canActivate: [ModelAccessibilityGuard],
      },
      // {
      //   path: 'model/:code',
      //   component: ViewprojectComponent,
      // },
      {
        path: 'information-page',
        component: InformationPageComponent,
        children: [
          {
            path: 'terms-of-use',
            component: TermsOfUseComponent,
            data: { informationPageNumber: InformationPageEnum.TERMS_OF_USE },
          },
          {
            path: 'privacy-policy',
            component: PrivacyPolicyComponent,
            data: { informationPageNumber: InformationPageEnum.PRIVACY_POLICY },
          },
          {
            path: 'cookie-policy',
            component: CookiesPolicyComponent,
            data: { informationPageNumber: InformationPageEnum.COOKIE_POLICY },
          },
        ],
      },
      {
        path: 'validate-selection/:polygonId',
        component: SelectionAcceptedComponent,
      },
      {
        path: 'deny-selection/:polygonId',
        component: SelectionDeniedComponent,
      },
      {
        path: 'model-not-found',
        component: ModelNotFoundComponent,
        canActivate: [PermissionGuard],
      },
    ],
  },
  {
    path: 'viewer/:code',
    loadChildren: () =>
      import('../viewer/viewer.module').then((m) => m.ViewerModule),
    canActivate: [ModelAccessibilityGuard],
    data: { preload: false },
  },
  {
    path: 'viewer/new/:code',
    loadChildren: () =>
      import('../viewer-new/viewer-new.module').then((m) => m.ViewerNewModule),
    data: { preload: false },
  },
  {
    path: '',
    loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'mobile-professional',
    component: MobileNoProfessionalNoticeComponent,
    canActivate: [PermissionGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'join-request/:token/approve',
    component: JoinRequestActionComponent,
  },
  {
    path: 'join-request/:token/deny',
    component: JoinRequestActionComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
