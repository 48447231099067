import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from 'src/app/models/model';
import { RestClientService } from '../../core/services/api-access/rest-client.service';

@Injectable({
  providedIn: 'root',
})
export class ModelStorageRemoteService {
  private URL = '/files';

  constructor(
    private restClientService: RestClientService
  ) /*private storageService: StorageService,
    private appConfigService: AppConfigService*/ {}

  public uploadObj(file: any, modelId: number): Observable<any> {
    return this.restClientService.post(`${this.URL}/upload/${modelId}`, file);
  }

  public delete(modelId: string, filename: string): Observable<any> {
    const file = {
      filename,
    };
    return this.restClientService.post(`${this.URL}/delete/${modelId}`, file);
  }

  public getStorage(id: number): Observable<number> {
    return this.restClientService.get(`${this.URL}/userStorage/${id}`);
  }
}
